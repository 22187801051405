import store from '../index';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import { Member } from '@/models/Member';
import { Mutex } from 'async-mutex';
import { loginHelper } from '@/main';

@Module
class MemberModule extends VuexModule {
    private _member: Member = new Member();
    private _failed: boolean = false;
    private _mutex = new Mutex();
    private _loading: boolean = false;

    public get member() {
        return this._member;
    }

    public get failed() {
        return this._failed;
    }

    public get loading() {
        return this._loading;
    }

    @Action
    public async fetchMember() {
        await this._mutex.runExclusive(async () => {
            if (!this._member.id) {
                this.SET_LOADING(true);
                const member = await loginHelper.getUser();
                this.SET_LOADING(false);
                this.SET_FAILED(!member);
                if (member) {
                    this.SET_MEMBER(member);
                }
            }
        });
    }

    @Action
    public async fetchMemberIfNeeded() {
        if (!this._member || !this.member.id || this._failed) {
            return await this.fetchMember();
        }
    }

    @Mutation
    public CLEAR_MEMBER() {
        this._member = new Member();
        this._failed = false;
    }

    @Mutation
    public SET_MEMBER(payload: Member) {
        this._member = payload;
    }

    @Mutation
    private SET_FAILED(payload: boolean) {
        this._failed = payload;
    }

    @Mutation
    private SET_LOADING(payload: boolean) {
        this._loading = payload;
    }
}

export const memberModule = new MemberModule({ store, name: 'member' });
