import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PageRender from '../../../../models/PageRender';
import { dictionaryHelper } from '@/main';
import moment from 'moment/min/moment.min.js';

@Component
export default class BasePriceRuleViewComponent extends PageRender {
    @Prop() public condition: any;
    public dictionary = dictionaryHelper;

    public getDay(key) {
        return this.dictionary.get('Days')[key];
    }

    public formatDate(date) {
        return moment(date).format(this.dictionary.get('DateFormats').ShortDate);
    }
}
