import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class GridAmountComponent extends Vue {
    public amount: string = '';

    public mounted() {
        this.amount = parseFloat(this.$data.templateArgs.value).toFixed(2);
    }
}
