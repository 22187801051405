import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class GridDateRange extends Vue {
    public from: Date = new Date();
    public to: Date = new Date();

    public changed() {
        this.$emit('filter-change', { from: this.from, to: this.to });
    }
}
