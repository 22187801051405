import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridSelectComponent extends Vue {
    @Prop() public items: [];
    @Prop() public textField: string;
    @Prop() public valueField: string;
    @Prop() public text: string;
    @Prop() public callback: any;
    @Prop() public route: string;

    public actionValueTemplate() {
        return `<span class='font-weight-normal'>${this.text}</span>`;
    }

    public actionTemplate(actionItem): string {
        return `<span>${actionItem[this.textField].toString()}</span>`;
    }

    public onSelect(e): void {
        this.callback(e.dataItem);
    }
}
