import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SearchPlaceTypeParamsInterface } from '../../../models/Interfaces';
import to from 'await-to-js';
import { bookingService } from '@/main';
import PageRender from '@/models/PageRender';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

@Component({
    components: {
        VueSlider,
    },
})
export default class PlaceTypeFilterCapacity extends PageRender {
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;
    @Prop() public maxCapacity: number;
    public travelTypes: any[] = [];
    public value = [1, 8];
    public min = 1;
    public max = 24;

    public $refs!: {
        slider: VueSlider;
    };

    public async mounted() {
        this.travelTypes = await this.getTravelTypes();
        this.max = this.maxCapacity;
        if (this.$refs.slider) {
            this.$refs.slider.setValue([1, this.max]);
        }

        this.travelTypes.forEach((type) => {
            type.amount = this.searchParams[type.travellerType];
        });
    }

    public capacityChanged(): void {
        this.$set(this.searchParams, 'capacity', this.getTo());

        if (this.getFrom() <= 0) {
            this.setFrom(1);
        }

        if (this.getTo() < this.getFrom()) {
            this.setTo(this.getFrom());
        }
    }

    public decreaseTravelType(type) {
        if (type.amount > 0) {
            type.amount--;
        } else {
            type.amount = 0;
        }

        if (type.travellerType === 'Pet' && type.amount === 0) {
            this.$set(this.searchParams, 'petsAllowed', false);
        }

        this.$set(this.searchParams, type.travellerType, type.amount);
    }

    public updateSearchParams() {
        this.setFrom(this.value[0]);
        this.setTo(this.value[1]);
    }

    public increaseTravelType(type) {
        type.amount++;

        if (type.travellerType === 'Pet') {
            this.$set(this.searchParams, 'petsAllowed', true);
        }

        this.$set(this.searchParams, type.travellerType, type.amount);
    }

    private getTravelTypes(): any[] {
        const types = bookingService.getTravelGroupTypes();
        types.map((travelType) => {
            return (travelType.amount = 0);
        });

        return types;
    }

    private getFrom(): number {
        return parseInt(this.searchParams.fromCapacity.toString(), 10);
    }

    private getTo(): number {
        return parseInt(this.searchParams.toCapacity.toString(), 10);
    }

    private setFrom(value: number) {
        this.$set(this.searchParams, 'fromCapacity', value);
    }

    private setTo(value: number) {
        this.$set(this.searchParams, 'toCapacity', value);
    }
}
