import { BaseService } from './baseService';
import Vue from 'vue';

export class PricesService extends BaseService {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;

    public savePrice(place: number, price: any) {
        return this.post(`${this.endpoint}/places/:place/price`, price, [
            ['place', place],
        ]);
    }

    public savePlaceTypePrices(placeTypeKey: string, price: any) {
        return this.post(`${this.endpoint}place-types/:place/price`, price, [
            ['place', placeTypeKey],
        ]);
    }
}
