import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import to from 'await-to-js';
import { siteService } from '@/main';
import { sitesModule } from '@/store/modules/site';

@Component({
    name: 'AccommodationType',
})
export default class AccommodationType extends BasePriceRuleComponent {
    public isLoading: boolean = false;
    public types = [];

    public get site() {
        return sitesModule.activeSite;
    }

    public async mounted() {
        this.isLoading = true;
        const [err, response] = await to(siteService.getPlaceTypes(this.site.siteKey));
        this.isLoading = false;

        if (err) {
            this.showError('Kan geen accommodatie types ophalen.');
            return;
        }

        this.types = response.data;
    }
}
