export default class NavigationHelper {
    public isNavVisible: boolean = false;
    public section: string = '';
    public scope: string = '';

    public toggleNavigation() {
        this.isNavVisible = !this.isNavVisible;
    }

    public hideNavigation() {
        this.isNavVisible = false;
    }

    public loadRouteMeta(meta: any, params: any) {
        this.scope = (meta.scope || 'park');
        this.section = (meta.section || 'overzicht');
    }
}
