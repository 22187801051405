import { BookingSource } from './BookingSource';
import { BookingChannel } from './BookingChannel';
import Booker from './Booker';
import SearchPrice from './Search/Price';

export default class Booking {

    public bookingId: number = null;
    public siteId: number;
    public mainBookerId: number = null;
    public externalReservationIdentifier: string = null;
    public mainBooker: Booker = null;
    public bookingState: number = 1;
    public arrivalDate: string = '';
    public departureDate: string = '';
    public source: string = BookingSource.Recreapi;
    public channel: string = BookingChannel.FrontOffice;
    public places: any[] = [];
    public placeIds: number[] = [];
    public placeTypeIds: any[] = [];
    public licensePlates: string[] = [];
    public selectedOptionalProducts: any[] = [];
    public travelGroup: any[] = [];
    public internalMemo: string = '';
    public additionalNote: string = '';
    public price: number = 0;
    public priceObject: SearchPrice = new SearchPrice();
    public tax: number = 0;
    public checkedIn: boolean = false;
    public checkedInDateTime: string = '';
    public checkedOutDateTime: string = '';
    public numberOfTravellers: number = 0;
    public discountCode: string = '';
    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }

    public totalNumberOfGuests() {
        let guestCount = 0;
        this.travelGroup.forEach((group: any) => {
            guestCount += group.amountOfTravellers;
        });

        return guestCount;
    }
}
