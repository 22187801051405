import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ImageInterface } from '../../models/Interfaces';
import PageRender from '../../models/PageRender';
import { mediaService } from '@/main';

@Component
export default class ImageUploader extends PageRender {
    @Prop() public refName: string;
    @Prop() public source: ImageInterface[];
    @Prop({ type: Boolean, default: true }) public multiple: boolean;
    public pendingImages: ImageInterface[] = [];
    private reader = new FileReader();

    // Image upload methods
    public uploadImageChanged(input) {
        if (input.files.length === 0) {
            return;
        }

        const self = this;
        this.reader.onload = (e) => {
            input.files[0].src = e.target.result;
            self.pendingImages = input.files;
        };

        this.reader.readAsDataURL(input.files[0]);
    }

    public async uploadPendingImages(): Promise<ImageInterface[]> {
        this.showPending('Foto uploaden...');
        const uploadedImages: ImageInterface[] = [];

        try {
            for (let i = 0; i < this.pendingImages.length; i++) {
                const response = (await mediaService.uploadImage(this.pendingImages[i])).data;
                this.clearNotifications();
                for (const image of response.Results) {
                    if (!image.success) {
                        this.showError(`Kan foto niet opslaan! <br/> <br/> '${image.error.replace('30mb', '15mb')}'`);
                        continue;
                    }

                    uploadedImages.push({
                        remoteImageId: image.Image.Data.Id,
                        identifier: image.Image.Data.Identifier,
                        title: image.Image.Data.Name,
                        description: image.Image.Data.Copyright,
                        url: image.Image.Uri,
                    } as ImageInterface);
                }
            }

            if (uploadedImages.length === this.pendingImages.length) {
                this.showSuccess(`Alle foto's succesvol opgeslagen.`);
            }
        } catch (e) {
            this.clearAndShowError('Kan foto niet opslaan!', e);
        }
        this.cancelUpload();
        return uploadedImages;
    }

    public cancelUpload() {
        this.pendingImages = [];
    }

    public async uploadImages() {
        const uploadedImages = await this.uploadPendingImages();
        for (let i = 0; i < uploadedImages.length; i++) {
            this.source.push(uploadedImages[i]);
        }

        this.$emit('uploaded', uploadedImages);
    }
}
