import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { VideoInterface } from '../../models/Interfaces';
import { BModal } from 'bootstrap-vue';

@Component
export default class AddVideo extends Vue {
    @Prop() public source: VideoInterface[];
    @Prop() public editMode: boolean;

    public newVideo: VideoInterface = {
        videoType: 'YouTube',
    } as VideoInterface;

    public $refs!: {
        newVideoModal: BModal,
    };

    public addNewVideo() {
        this.source.push(this.newVideo);
        this.newVideo = {} as VideoInterface;
        this.$refs.newVideoModal.hide();
    }
}
