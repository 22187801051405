import { BaseService } from './baseService';
import Vue from 'vue';

export class MailService extends BaseService {
    private endpoint = Vue.$env().MailServiceEndpoint + 'api/mail/';
    private templatesGetEndpoint = Vue.$env().MailServiceEndpoint;

    public getEmailTemplates(id: number, isPark: boolean) {
        if (isPark) {
            return this.get(`${this.templatesGetEndpoint}templates?site_id=${id}`);
        }

        return this.get(`${this.templatesGetEndpoint}templates?site_owner_id=${id}`);
    }

    public updateTemplate(id, model) {
        return this.put(`${this.endpoint}templates/:id`, model, [['id', id]]);
    }
}
