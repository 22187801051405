import Component from 'vue-class-component';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import HomeOwner from '@/models/HomeOwner';
import { homeOwnerService } from '@/main';
import moment from 'moment';

enum CSVHeaders {
    FirstName = 'firstName',
    Insertion = 'insertion',
    LastName = 'lastName',
    DateOfBirth = 'dateOfBirth',
    EmailAddress = 'emailAddress',
    PhoneNumber = 'phoneNumber',
    StreetName = 'streetName',
    HouseNumber = 'houseNumber',
    PostalCode = 'postalCode',
    City = 'city',
    CompanyName = 'companyName',
    Iban = 'Iban',
    CocNumber = 'cocNumber',
    AltEmailAddress = 'altEmailAddress',
    AltPhoneNumber = 'altPhoneNumber',
    Notes = 'notes',
    InternalNote = 'internalNote',
    AccommodationIds = 'accommodations',
}

@Component
export default class UploadHomeOwnersSideAction extends SideActionBase<any> {
    public isLoading: boolean = false;
    public isUploadingCsv: boolean = false;
    public submitted: boolean = false;

    public importedHomeOwners: HomeOwner[] = [];
    public importedHomeOwnersColumns = [
        { field: 'firstName', width: '150px', title: 'Voornaam' },
        { field: 'insertion', width: '180px', title: 'tussenvoegsel' },
        { field: 'lastName', width: '150px', title: 'Achternaam' },
        { field: 'dateOfBirth', width: '180px', title: 'Geboortedatum' },
        { field: 'emailAddress', width: '180px', title: 'Emailadres' },
        { field: 'phoneNumber', width: '200px', title: 'Telefoonnummer' },
        { field: 'streetName', width: '150px', title: 'Straat' },
        { field: 'houseNumber', width: '150px', title: 'Huisnummer' },
        { field: 'postalCode', width: '150px', title: 'Postcode' },
        { field: 'city', width: '150px', title: 'Stad' },
        { field: 'companyName', width: '150px', title: 'Bedrijfsnaam' },
        { field: 'iban', width: '150px', title: 'IBAN' },
        { field: 'cocNumber', width: '150px', title: 'KVK' },
        { field: 'altEmailAddress', width: '250px', title: 'Alternatief emailadres' },
        { field: 'altPhoneNumber', width: '250px', title: 'alternatief telefoonnummer' },
        { field: 'notes', width: '150px', title: 'Notities' },
        { field: 'internalNote', width: '150px', title: 'Interne notitie' },
        { field: 'accommodations', width: '200px', title: 'Accommodatienummers' },
    ];

    private csvColumns: CsvColumn[] = [
        { label: 'firstName', placeholder: 'firstName' },
        { label: 'insertion', placeholder: 'insertion' },
        { label: 'lastName', placeholder: 'lastName' },
        { label: 'dateOfBirth', placeholder: 'dd-mm-yyyy' },
        { label: 'emailAddress', placeholder: 'name@exmaple.com' },
        { label: 'phoneNumber', placeholder: '0623232323' },
        { label: 'streetName', placeholder: 'street' },
        { label: 'houseNumber', placeholder: '12' },
        { label: 'postalCode', placeholder: '6781EZ' },
        { label: 'city', placeholder: 'Arnhem' },
        { label: 'companyName', placeholder: 'Recreapi BV' },
        { label: 'iban', placeholder: 'NL48ABN12312312' },
        { label: 'cocNumber', placeholder: '78913812' },
        { label: 'altEmailAddress', placeholder: '<optional>' },
        { label: 'altPhoneNumber', placeholder: '<optional>' },
        { label: 'notes', placeholder: '' },
        { label: 'internalNote', placeholder: '' },
        {
            label: 'accommodations',
            placeholder: `List of Ids from accommodation: 1,2,3,4, etcetra. Look at => https://recreapi.com/park/accommodaties/33 in this case it's 33`,
        },
    ];

    public async created() {
        this.isLoading = false;
    }

    public homeOWnerCreated(e) {
        this.finished(e);
    }

    public loadCSV(e: any) {
        this.submitted = true;
        if ((window as any).FileReader) {
            const reader = new FileReader();
            reader.readAsText(e.target.files[0]);
            // Handle errors load
            reader.onload = (event) => {
                const csv = (event.target as any).result;
                this.importedHomeOwners = this.csvJSON(csv);
            };

            reader.onerror = (evt) => {
                if ((evt.target as any).error.name === 'NotReadableError') {
                    alert(`Canno't read file !`);
                }
            };
        } else {
            alert('FileReader are not supported in this browser.');
        }
    }

    public csvJSON(csv, delimiter = ';') {
        const lines = csv.split('\n');
        const result = [];
        const headers = lines[0].split(delimiter).map((header) => {
            return header.replace('\r', '');
        });

        for (let lineIndex = 1; lineIndex < lines.length; lineIndex++) {
            const line = lines[lineIndex];
            const obj = {};
            const currentline = line.split(delimiter);
            let invalidRow = false;

            for (let headerIndex = 0; headerIndex < headers.length; headerIndex++) {
                const header = headers[headerIndex];
                let cell = currentline[headerIndex];

                if (cell) {
                    cell = cell.replace(`'`, '').replace('\r', '');
                }

                let value: any;
                switch (header) {
                    case CSVHeaders.AccommodationIds:
                        const accoIds: string[] = cell.split(',');
                        value = accoIds.map((x) => parseInt(x));
                        break;
                    case CSVHeaders.DateOfBirth:
                        value = moment(cell, 'DD/MM/YYYY').toDate();
                        break;
                    case CSVHeaders.EmailAddress:
                    case CSVHeaders.FirstName:
                    case CSVHeaders.LastName:
                        if (cell === '') {
                            invalidRow = true;
                        }
                        value = cell;
                        break;
                    case CSVHeaders.PhoneNumber:
                        if (!cell.startsWith('+') || !cell.startsWith('0')) {
                            value = '0' + cell;
                        } else {
                            value = cell;
                        }
                        break;
                    default:
                        value = cell;
                        break;
                }

                if (invalidRow) {
                    break;
                } else {
                    obj[header] = value;
                }
            }

            if (invalidRow) {
                invalidRow = false;
                continue;
            }
            result.push(new HomeOwner(obj));
        }
        return result; // JavaScript object
    }

    public downloadFile(response: any, filename: any): void {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: 'text/csv' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob);
        //     return;
        // }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename + '.csv';
        link.click();
        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    public async uploadHomeOwners() {
        this.isUploadingCsv = true;

        const postModel = this.importedHomeOwners.map((element) => {
            const homeOwner = new HomeOwner(element);
            return homeOwner;
        });

        const success = await homeOwnerService.uploadMultipleHomeOwners(postModel);
        if (!success) {
            this.showFailedResponse('Mislukt om items op te slaan', null);
            return;
        }

        this.finished();
    }

    public downloadExample() {
        this.exportExampleCSV(this.csvColumns);
    }

    public exportExampleCSV(columns: CsvColumn[]): void {
        const properties = [];
        const values = [];
        const lines = [];
        for (const prop of columns) {
            values.push(prop.placeholder);
            properties.push(prop.label);
        }

        lines.push(properties.join(';'));
        lines.push(values.join(';'));

        const blob = new Blob([lines.join('\n')], { type: 'text/csv' });
        const downloadLink = document.createElement('a');
        downloadLink.download = 'ExampleImport.csv';
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}

// tslint:disable-next-line: max-classes-per-file
export class CsvColumn {
    public label: string = '';
    public placeholder: string = '';
}
