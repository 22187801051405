import Vue from 'vue';
import { BaseService } from './baseService';
import { AffiliatePlaceStatus } from '@/models/Affiliate/AffiliatePlaceStatus';

export class AffiliatePlacesService extends BaseService {
    public params: any[] = [];

    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'affiliate-places';

    public getPromotionStatus(placeId: number) {
        return this.get<AffiliatePlaceStatus>(`${this.endpoint}?placeId=:placeId`, [['placeId', placeId]]);
    }

    public promoteAccommodation(placeId: number) {
        return this.post(`${this.endpoint}`, { placeId }, null);
    }

    public demoteAccommodation(placeId: number): Promise<any> {
        return this.delete(`${this.endpoint}?placeId=:placeId`, [['placeId', placeId]]);
    }
}
