import { BaseService } from './baseService';
import Vue from 'vue';
import { UserRoles } from '../models/UserRoles';
import { Member } from '../models/Member';
import to from 'await-to-js';
import InviteUser from '@/models/InviteUser';

export default class UserService extends BaseService {
    private endpoint = `${Vue.$env().IdentityEndpoint}api/roles/`;
    private apiEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;
    private addDeleteEndpoint = `${this.endpoint}user/:personId/role/:role`;

    public getUsersInRole(role: UserRoles) {
        return this.get(`${this.endpoint}:role/users`, [['role', role]]);
    }

    public makeAddDeleteData(personId: number, role: UserRoles): ReadonlyArray<[string, any]> {
        return [
            ['personId', personId],
            ['role', role as string],
        ];
    }

    public getRecreapiUsers() {
        return this.get(`${this.apiEndpoint}users/`);
    }

    public createRecreapiUser(model: Member) {
        return this.post(`${this.apiEndpoint}users/`, model);
    }

    public setUserRole(personId: number, role: UserRoles) {
        return this.post(this.addDeleteEndpoint, null, this.makeAddDeleteData(personId, role));
    }

    public deleteUserRole(personId: number, role: UserRoles) {
        return this.delete(this.addDeleteEndpoint, this.makeAddDeleteData(personId, role));
    }

    public saveSites(userId: number, siteIds: number[]): Promise<any> {
        return this.put(`${this.apiEndpoint}users/:userId/sites`, { siteIds }, [['userId', userId]]);
    }

    public async getUsers() {
        const [err, respon] = await to(this.get(`${this.apiEndpoint}users`));
        if (err) {
            return [];
        }

        return respon.data;
    }

    public inviteUser(user: InviteUser) {
        return this.post(`${this.apiEndpoint}invites`, user);
    }

    public getInvite(key: string) {
        return this.get(`${this.apiEndpoint}/invites/:key`, [['key', key]]);
    }

    public claimInvite(key: string, passsword: string) {
        return this.post(`${this.apiEndpoint}/invites/:key`, { password: passsword }, [['key', key]]);
    }

    public async deleteUser(personId: number) {
        const [err] = await to(this.delete(`${this.apiEndpoint}users/${personId}`));
        return !err;
    }
}
