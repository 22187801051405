import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import { dictionaryHelper, bookingService } from '@/main';

@Component({
    name: 'ContainsTravellerType',
})
export default class ContainsTravellerType extends BasePriceRuleComponent {
    public dictionary = dictionaryHelper;

    public types: any[] = [];

    public async mounted() {
        this.types = bookingService.getTravelGroupTypes();
    }
}
