import { bookingService } from '@/main';
import Booking from '@/models/Booking';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class TravelGroup extends Vue {
    @Prop({ required: true }) public booking: Booking;
    public travelTypes: any[] = [];

    public mounted() {
        this.travelTypes = this.getTravelTypes();
        this.loadTravellersFromBooking();
        this.$watch('travelTypes', this.watchTravelTypes, { deep: true });
    }

    public async watchTravelTypes() {
        this.booking.travelGroup = [];
        this.travelTypes.forEach((type) => {
            if (type.amount > 0) {
                this.booking.travelGroup.push({
                    amountOfTravellers: type.amount,
                    travelGroupType: type.type,
                });
            }
        });
    }

    public increaseTravelType(type) {
        type.amount++;
    }

    public decreaseTravelType(type) {
        if (type.amount > 0) {
            type.amount--;
        } else {
            type.amount = 0;
        }
    }

    private loadTravellersFromBooking() {
        this.booking.travelGroup.forEach((group) => {
            const traveller = this.travelTypes.find((type) => {
                return type.type === group.travelGroupType;
            });
            if (traveller) {
                traveller.amount = group.amountOfTravellers;
            }
        });
    }

    private getTravelTypes(): any[] {
        const types = bookingService.getTravelGroupTypes();
        types.map((travelType) => {
            return (travelType.amount = 0);
        });
        return types;
    }
}
