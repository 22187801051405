import BookerAddress from './BookerAddress';
import { dateHelper } from '@/main';

export default class Booker {
    public mainBookerId: number;
    public homeOwnerId: number;
    public bookerType: number = 1;
    public firstName: string;
    public lastName: string;
    public insertion: string;
    public dateOfBirth: any;
    public emailAddress: string;

    public licensePlate: any;
    public phoneNumber: string;
    public address: BookerAddress = new BookerAddress();
    public isHufter: boolean = false;
    public city: string = '';
    public houseNumber: string = '';
    public postalCode: string = '';
    public streetName: string = '';
    public country: string = '';
    public note: string = '';

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }

    public name() {
        const parts = [this.firstName, this.insertion, this.lastName];
        parts.filter((value) => !!value);

        return parts.join(' ');
    }

    public birthday() {
        return dateHelper.format(this.dateOfBirth, 'D MMMM YYYY');
    }
}
