import Component from 'vue-class-component';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import HomeOwner from '@/models/HomeOwner';
import { homeOwnerService } from '@/main';

@Component
export default class LegalEntityPage extends SideActionBase<any> {
    @Prop({ default: () => new HomeOwner() }) public homeOwner: HomeOwner;
    public localHomeOwner: HomeOwner = new HomeOwner();
    public isEdit: boolean = false;
    public isLoading: boolean = false;
    public submitted: boolean = false;

    public async created() {
        this.isEdit = this.options.homeOwner && this.options.homeOwner.homeOwnerId !== '';
        if (this.isEdit) {
            this.localHomeOwner = this.options.homeOwner;
        }
        this.isLoading = false;
    }

    public resetOrganization() {
        this.localHomeOwner = new HomeOwner();
    }

    public homeOWnerCreated(e) {
        this.finished(e);
    }

    public async save() {
        this.showPending('Huiseigenaar opslaan..');
        const [err, response] = await to(homeOwnerService.saveHomeOwner(this.localHomeOwner));
        if (err) {
            this.clearAndShowError('Mislukt om huiseigenaar op te slaan');
            return;
        }

        this.clearAndShowSuccess('Huiseigenaar opgeslagen');

        this.homeOwner.homeOwnerId = response.data.homeOwnerId;

        this.$emit('created', this.homeOwner);
        this.homeOWnerCreated(this.homeOwner);
    }
}
