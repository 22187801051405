import Vue from 'vue';
import { Member } from '../models/Member';
import to from 'await-to-js';
import { loginService, tenantService } from '@/main';
import { Mutex } from 'async-mutex';
import { memberModule } from '@/store/modules/member';
import { sitesModule } from '@/store/modules/site';
import { $router } from '@/router';

export class LoginHelper {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;
    private _mutex = new Mutex();

    public async getUser(): Promise<Member> {
        const tenant: any = await tenantService.getTenantInfoByDomain(window.location.hostname);
        let user = new Member();

        await this._mutex.runExclusive(async () => {
            const [err, response] = await to(
                loginService.authAxiosInstance.get<Member>(`${this.endpoint}users/profile?tenant=${tenant.TenantId}`),
            );
            if (err || !response) {
                this.logout();
                console.error('Could not get profile information.');
                return null;
            } else {
                user = new Member(response.data);
            }
        });

        return user;
    }

    public isLoggedIn(): boolean {
        return loginService.isLoggedIn();
    }

    public logout() {
        loginService.logout();
        memberModule.CLEAR_MEMBER();
        sitesModule.CLEAR_SITES();
        $router.push({ name: 'login' });
    }

    public getSiteOwner(): number {
        if (!this.isLoggedIn) {
            return null;
        }
        const jwt = loginService.getJwtData();
        return parseInt(jwt['https://schemas.recreapi.com/claims/site-owner'], 10);
    }

    public getGivenName(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        return loginService.getJwtData().unique_name;
    }

    public getProfilePicture(): string {
        if (!this.isLoggedIn) {
            return '';
        }

        return loginService.getJwtData().picture;
    }

    public getExpirationDate(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        return loginService.getJwtData().exp;
    }

    public getRole(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        return loginService.getJwtData().role;
    }

    public getUserId(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        return loginService.getJwtData()['https://schemas.bright-ecology.io/identity/claims/personId'];
    }

    public getEmailaddress(): string {
        if (!this.isLoggedIn) {
            return '';
        }
        return loginService.getJwtData().unique_name;
    }

    public getRoles(): string[] {
        if (!this.isLoggedIn) {
            return [];
        }
        const roles = loginService.getJwtData().role;
        return typeof roles === 'string' ? [roles] : roles;
    }
}
