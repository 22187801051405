import Vue from 'vue';
import { BaseService } from './baseService';
import Place from '../models/Place';

export class PlaceService extends BaseService {
    private placeEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;

    public getPlaces(siteKey: string, homeOwnerId: number) {
        return this.get<Place[]>(`${this.placeEndpoint}/places?siteKey=:siteKey&homeOwnerId=:homeOwnerId`, [
            ['siteKey', siteKey],
            ['homeOwnerId', homeOwnerId],
        ]);
    }

    public indexRentOrServiceCostsOfPlaces(percentage: number, siteId: number) {
        return this.post(`${this.placeEndpoint}places/index?siteId=:siteId`, { percentage }, [['siteId', siteId]]);
    }

    public saveUsage(accommodation: any): any {
        throw new Error('Method not implemented.');
    }
}
