import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SearchPlaceTypeParamsInterface } from '../../../models/Interfaces';
import FacetGroup from '../../../models/Search/FacetGroup';

@Component
export default class PlaceTypeFilterFeature extends Vue {
    @Prop({ default: () => [] }) public facets: any[];
    @Prop({ default: () => [] }) public allFacets: any[];
    @Prop({ default: () => ({} as any) }) public selected: any;
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;

    public facetGroups: FacetGroup[] = [];
    public cachedFacetGroups: FacetGroup[] = [];
    public touchyFacetGroup: string = null;

    public async mounted() {
        this.cachedFacetGroups = this.getAllFacets();
    }

    public getFacetsFromGroups() {
        const facets = [];
        this.allFacets.forEach((facetGroup: FacetGroup) => {
            if (facetGroup.facets) {
                facetGroup.facets.forEach((facet: any) => {
                    facets.push({
                        name: facet.label,
                        group: facetGroup.groupName,
                    });
                });
            }
        });

        return facets;
    }

    public getAllFacets() {
        const self = this;
        const cachedFacetGroup = this.cachedFacetGroups.find((fg) => this.touchyFacetGroup && this.touchyFacetGroup.startsWith(fg.name));
        const facetGroups = [] as FacetGroup[];

        this.allFacets.forEach((facetGroup) => {
            const group = {
                name: facetGroup.groupName.split('_')[0],
                facets: [],
            } as FacetGroup;

            if (this.touchyFacetGroup && cachedFacetGroup && facetGroup.groupName === this.touchyFacetGroup) {
                group.facets = cachedFacetGroup.facets;
            } else {
                facetGroup.facets.forEach((facet) => {
                    group.facets.push({
                        name: facet.label,
                        group: facetGroup.groupName,
                        count: self.getFacetCount(facetGroup.groupName, facet.label),
                    });
                });
            }

            facetGroups.push(group);
        });

        return facetGroups;
    }

    public getFacetCount(groupName, label) {
        const group = this.facets.find((x) => x.groupName === groupName);
        let facet = null;

        if (group) {
            facet = group.facets.find((y) => y.label === label);
        } else {
            return 0;
        }

        return facet ? facet.count : 0;
    }

    public toggleSelectedFacet(group, label) {
        this.cachedFacetGroups = this.getAllFacets();
        this.touchyFacetGroup = group;

        if (!this.selected[group]) {
            this.selected[group] = [];
        }

        const index = this.selected[group].indexOf(label);
        if (index > -1) {
            this.selected[group].splice(index, 1);
        } else {
            this.selected[group].push(label);
        }

        this.searchParams.facets = this.getFacetsQuery();
    }

    public isFacetChecked(group, label) {
        return this.selected[group] && this.selected[group].indexOf(label) > -1 ? true : false;
    }

    private getFacetsQuery(): string {
        const query = [];
        const keys = Object.keys(this.selected);
        keys.forEach((key) => {
            if (this.selected[key].length > 0) {
                query.push(`${key}=${this.selected[key].join('|')}`);
            }
        });

        return query.join('&');
    }
}
