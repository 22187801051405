import Component from 'vue-class-component';
import { loginHelper } from '@/main';
import BasePage from '@/models/BasePage';
import { memberModule } from '@/store/modules/member';

@Component
export default class NavigationProfile extends BasePage {
    public get user() {
        return memberModule.member;
    }

    public get currentUser(): string {
        const profile = this.user;
        if (profile) {
            let parts = [profile.firstName, profile.insertion, profile.lastName];
            parts = parts.filter((value) => !!value);
            return parts.join(' ');
        }
        return '';
    }

    public logOut() {
        return loginHelper.logout();
    }
}
