import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { loginHelper, roleHelper } from './main';
import Site from './models/site';
import { Member } from './models/Member';
import { sitesModule } from './store/modules/site';
import { memberModule } from './store/modules/member';

@Component
export default class AppComponent extends Vue {
    public sites: Site[] = [];
    public user: Member = null;

    public get siteId() {
        return sitesModule.activeSite.siteId;
    }

    public get siteKey() {
        return sitesModule.activeSite.siteKey;
    }

    public get navigationItems() {
        const siteId = sitesModule.activeSite.siteId;
        const siteKey = sitesModule.activeSite.siteKey;

        const parkItems: any = {
            id: 'park',
            variant: 'success-clean',
            icon: 'trello',
            title: 'Park',
            children: [
                {
                    route: 'site',
                    params: { siteId, siteKey },
                    label: 'Parkoverzicht',
                    icon: 'database',
                },
                {
                    route: 'site-facilities',
                    params: { siteId, siteKey },
                    label: 'Parkfaciliteiten',
                    icon: 'list',
                },
                {
                    route: 'site-price-rules',
                    params: { siteId, siteKey },
                    label: 'Business regels',
                    icon: 'command',
                },
                {
                    route: 'site-products',
                    params: { siteId, siteKey },
                    label: 'Producten en kortingen',
                    icon: 'shopping-bag',
                },
                {
                    route: 'site-discounts',
                    params: { siteId, siteKey },
                    label: 'Kortingscodes',
                    icon: 'percent',
                },
            ],
        };

        const accoItems: any = {
            id: 'acco',
            variant: 'success-clean',
            icon: 'home',
            title: 'Accommodaties',
            children: [
                {
                    route: 'accommodation-search',
                    params: { siteId, siteKey },
                    label: 'Zoeken',
                    icon: 'search',
                },
                {
                    route: 'accommodation-map',
                    params: { siteId, siteKey },
                    label: 'Plattegrond',
                    icon: 'map',
                },
                {
                    route: 'accommodation-types',
                    params: { siteId, siteKey },
                    label: 'Accommodatietypes',
                    icon: 'trending-up',
                },
                {
                    route: 'accommodations',
                    params: { siteId, siteKey },
                    label: 'Accommodaties',
                    icon: 'home',
                },
                {
                    route: 'accommodation-planning',
                    params: { siteId, siteKey },
                    label: 'Planbord',
                    icon: 'calendar',
                },
            ],
        };

        const bookingItems: any = {
            id: 'booking',
            variant: 'primary-clean',
            icon: 'trending-up',
            title: 'Boekingen',
            children: [
                {
                    route: 'bookings',
                    params: { siteId, siteKey },
                    label: 'Boekingen',
                    icon: 'layout',
                },
                {
                    route: 'bookers',
                    params: { siteId, siteKey },
                    label: 'Hoofdboekers',
                    icon: 'user',
                },
                {
                    route: 'activeguests',
                    params: { siteId, siteKey },
                    label: 'Huidige bezoekers',
                    icon: 'users',
                },
                {
                    route: 'checks',
                    params: { siteId, siteKey },
                    label: `Check-in/out's`,
                    icon: 'user-check',
                },
            ],
        };

        const settingsItems: any = {
            id: 'settings',
            variant: 'dark-clean',
            icon: 'settings',
            title: 'Instellingen',
            children: [
                {
                    route: 'bank-holidays',
                    params: { siteId, siteKey },
                    label: 'Vakantieperiodes',
                    icon: 'layout',
                },
                {
                    route: 'roles',
                    label: 'Gebruikers',
                    icon: 'users',
                },
                {
                    route: 'invites',
                    label: 'Uitnodigingen',
                    icon: 'send',
                },
            ],
        };

        const financeItems: any = {
            id: 'finance',
            variant: 'warning-clean',
            icon: 'finance',
            title: 'Financieel',
            children: [
                {
                    route: 'invoices',
                    params: { siteId, siteKey },
                    label: 'Facturen',
                    icon: 'file-text',
                },
                {
                    route: 'cash-register',
                    params: { siteId, siteKey },
                    label: 'Losse verkoop',
                    icon: 'file',
                },
                {
                    route: 'site-finance-accounts',
                    params: { siteId, siteKey },
                    label: 'Grootboekrekeningen',
                    icon: 'sliders',
                },
                {
                    route: 'site-invoice-data',
                    params: { siteId, siteKey },
                    label: 'Factuurgegevens',
                    icon: 'paperclip',
                },
                {
                    route: 'payment-stats',
                    params: { siteId, siteKey },
                    label: 'Statistieken',
                    icon: 'trending-up',
                },
            ],
        };

        if (roleHelper.isRecreapiSiteAdmin() || roleHelper.isRecreapiSystemAdmin()) {
            parkItems.children.push({
                route: 'emails',
                label: 'E-mails',
                icon: 'mail',
            });

            settingsItems.children.push(
                ...[
                    // Peter wants this items disabled on system level,
                    // we think it will be necessary when you have more parks
                    // {
                    //     route: 'price-rules',
                    //     label: 'Business regels',
                    //     icon: 'command',
                    // },
                    // {
                    //     route: 'finance-accounts',
                    //     label: 'Grootboekrekeningen',
                    //     icon: 'sliders',
                    // },
                    // {
                    //     route: 'discounts',
                    //     label: 'Kortingscodes',
                    //     icon: 'percent',
                    // },
                    // {
                    //     route: 'products',
                    //     label: 'Producten en kortingen',
                    //     icon: 'shopping-bag',
                    // },
                    // {
                    //     route: 'site-owner-emails',
                    //     label: 'E-mails',
                    //     icon: 'mail',
                    // },
                    {
                        route: 'facilities',
                        label: 'Parkfaciliteiten',
                        icon: 'list',
                    },
                    {
                        route: 'features',
                        label: 'Faciliteiten',
                        icon: 'list',
                    },
                ],
            );
        }

        const ownersItems: any = {
            id: 'owners',
            variant: 'success-clean',
            icon: 'users',
            title: 'Eigenaren',
            children: [
                {
                    route: 'home-uniek-owner',
                    params: { siteId, siteKey },
                    label: 'Eigenaren',
                    icon: 'users',
                },
                {
                    route: 'home-owner-accos',
                    params: { siteId, siteKey },
                    label: 'Accommodaties',
                    icon: 'home',
                },
            ],
        };

        const homeOwnerItems: any = {
            id: 'homeowner',
            variant: 'success-clean',
            icon: 'user',
            title: 'Huiseigenaar',
            children: [
                {
                    route: 'my-home-owner-accommodations',
                    label: 'Mijn huisjes',
                    icon: 'home',
                },
                {
                    route: 'my-home-owner-invoices',
                    label: 'Mijn facturen',
                    icon: 'trending-up',
                },
            ],
        };

        let navItems = [];

        if (roleHelper.isRecreapiHomeOwner()) {
            navItems.push(homeOwnerItems);
        } else {
            navItems = [bookingItems, parkItems, accoItems, ownersItems, financeItems, settingsItems];
        }

        return navItems;
    }

    public get loading() {
        return sitesModule.loading || memberModule.loading;
    }

    @Watch('$route', { immediate: true, deep: true })
    public async watchRouteChange() {
        if (loginHelper.isLoggedIn()) {
            await this.initApp();
        }
    }

    public sitesHasError() {
        return sitesModule.failed;
    }

    public showNav() {
        return loginHelper.isLoggedIn(); // && !sitesModule.failed;
    }

    private async initApp() {
        if (this.loading) {
            return;
        }

        await memberModule.fetchMemberIfNeeded();
        if (!roleHelper.isRecreapiHomeOwner()) {
            await sitesModule.fetchSitesIfNeeded();
        }
    }
}
