import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SearchPlaceTypeParamsInterface } from '../../../models/Interfaces';
import FacetGroup from '../../../models/Search/FacetGroup';

@Component
export default class PlaceTypeFilterSpecialties extends Vue {
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;
}
