import Vue from 'vue';
import Router, { Route } from 'vue-router';
import VueRouter from 'vue-router';
import { navigationHelper, loginHelper, roleHelper } from './main';
import { UserRoles } from './models/UserRoles';
import { Member } from './models/Member';
import { sitesModule } from './store/modules/site';
import { memberModule } from './store/modules/member';
import Site from './models/site';

Vue.use(Router);

const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "home" */ '@/pages/home/home.vue'),
        meta: { menu: 'default-menu', scope: 'welkom', global: 'welkom' },
    },
    { name: 'error', path: '/fout-pagina', component: () => import(/* webpackChunkName: "error" */ '@/pages/error/error.vue') },
    {
        name: 'login',
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/account/login/login.vue'),
        meta: { title: 'Inloggen' },
    },
    {
        name: 'set-password',
        path: '/wachtwoord-instellen',
        component: () => import(/* webpackChunkName: "login" */ '@/pages/account/login/login.vue'),
    },
    {
        name: 'invite',
        path: '/uitnodiging/:key',
        component: () => import(/* webpackChunkName: "uitnodiging" */ '@/pages/account/invite/invite.vue'),
        meta: { title: 'Team - Invite' },
    },
    // Site
    {
        name: 'site-base',
        path: '/:siteId-:siteKey/',
        component: () => import(/* webpackChunkName: "site" */ '@/pages/site/base.vue'),
        redirect: { name: 'site' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'site',
                path: 'overzicht',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/overview.vue'),
                meta: { scope: 'park', section: 'overzicht', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-description',
                path: 'beschrijving',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/description.vue'),
                meta: { scope: 'park', section: 'beschrijving', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-price-rules',
                path: 'businessregels',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/price-rules.vue'),
                meta: { scope: 'park', section: 'instellingen', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-price-rule',
                path: 'businessregels/:priceRuleKey',
                component: () => import(/* webpackChunkName: "site2" */ '@/pages/site/price-rule.vue'),
                meta: { scope: 'park', section: 'instellingen', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-products',
                path: 'productenenkortingen',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/products.vue'),
                meta: { scope: 'park', section: 'instellingen', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-product',
                path: 'productenenkortingen/:productId',
                component: () => import(/* webpackChunkName: "site2" */ '@/pages/site/product.vue'),
                meta: { scope: 'park', section: 'instellingen', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-facilities',
                path: 'faciliteiten',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/facilities.vue'),
                meta: { scope: 'park', section: 'faciliteiten', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-discounts',
                path: 'kortingscodes',
                component: () => import(/* webpackChunkName: "site" */ '@/pages/site/discounts.vue'),
                meta: { scope: 'park', section: 'kortingscodes', global: 'site-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'emails',
                path: 'emails',
                component: () => import(/* webpackChunkName: "mail-edit" */ '@/pages/email/edit.vue'),
                meta: {
                    scope: 'park',
                    section: 'email',
                    global: 'site-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
        ],
    },
    // Accommodations
    {
        name: 'acco-base',
        path: '/:siteId-:siteKey/',
        component: () => import(/* webpackChunkName: "site" */ '@/pages/accommodations/base.vue'),
        redirect: { name: 'accommodation-search' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'accommodation-types',
                path: 'accommodatie-types',
                component: () => import(/* webpackChunkName: "place" */ '@/pages/accommodations/types.vue'),
                meta: { scope: 'accommodatie', section: 'types', global: 'acco-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'acco-type',
                path: 'accommodatie-types/:typeKey',
                component: () => import(/* webpackChunkName: "place2" */ '@/pages/accommodations/type.vue'),
                meta: {
                    scope: 'accommodatie',
                    section: 'types',
                    global: 'acco-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'accommodations',
                path: 'accommodaties',
                component: () => import(/* webpackChunkName: "excel" */ '@/pages/accommodations/overview.vue'),
                meta: { scope: 'accommodatie', section: 'overzicht', global: 'acco-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'acco',
                path: 'accommodaties/:placeKey/:mode?',
                component: () => import(/* webpackChunkName: "place2" */ '@/pages/accommodations/accommodation.vue'),
                meta: {
                    scope: 'accommodatie',
                    section: 'plek',
                    global: 'acco-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'accommodation-planning',
                path: 'accommodatie-planbord',
                component: () => import(/* webpackChunkName: "place-planning" */ '@/pages/accommodations/planning.vue'), // require('./pages/accommodations/planning').default,
                meta: { scope: 'accommodatie', section: 'planning', global: 'acco-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            // {
            //     name: 'accommodation-features',
            //     path: 'accommodatie-eigenschappen',
            //     component: () => import(/* webpackChunkName: "place" */ '@/pages/accommodations/features.vue'),
            //     meta: { scope: 'accommodatie', section: 'eigenschappen', global: 'acco-base', excludedRoles: [UserRoles.RecreapiFrontOffice] },
            // },
            {
                name: 'accommodation-search',
                path: 'accommodatie-zoeken',
                component: () => import(/* webpackChunkName: "place" */ '@/pages/accommodations/search.vue'),
                meta: { scope: 'accommodatie', section: 'zoeken', global: 'acco-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'accommodation-map',
                path: 'accommodatie-plattegrond',
                component: () => import(/* webpackChunkName: "place" */ '@/pages/accommodations/map.vue'),
                meta: { scope: 'accommodatie', section: 'plattegrond', global: 'acco-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
        ],
    },
    // Settings
    {
        name: 'setting-base',
        path: '/instellingen',
        component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/base.vue'),
        redirect: { name: 'bank-holidays' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'bank-holidays',
                path: 'vakantieperiodes',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/bank-holidays.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'vakantieperiodes',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'price-rules',
                path: 'systeem-businessregels',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/price-rules.vue'),
                // component: require('./pages/settings/price-rules').default,
                meta: {
                    scope: 'instellingen',
                    section: 'businessregels',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'price-rule',
                path: 'systeem-businessregels/:priceRuleKey',
                component: () => import(/* webpackChunkName: "settings2" */ '@/pages/settings/price-rule.vue'),
                meta: { scope: 'instellingen', section: 'businessregels', global: 'setting-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'discounts',
                path: 'systeem-kortingscodes',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/discounts.vue'),
                meta: { scope: 'instellingen', section: 'kortingscodes', global: 'setting-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'features',
                path: 'systeem-eigenschappen',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/accommodations/features.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'eigenschappen',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'facilities',
                path: 'systeem-faciliteiten',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/site/facilities.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'faciliteiten',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },

            // {
            //     name: 'roles',
            //     path: 'rollen',
            //     component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/roles.vue'),
            //     meta: { scope: 'instellingen', section: 'rollen', global: 'setting-base', excludedRoles: [UserRoles.RecreapiFrontOffice] },
            // },
            {
                name: 'finance-accounts',
                path: 'systeem-grootboekrekeningen',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/finance-accounts.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'Grootboekrekeningen',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'products',
                path: 'systeem-producten',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/products.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'Producten',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'product',
                path: 'systeem-producten/:productId',
                component: () => import(/* webpackChunkName: "settings2" */ '@/pages/settings/product.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'Producten',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                path: 'rollen',
                name: 'roles',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/roles/roles.vue'),
                meta: {
                    title: 'Instellingen',
                    scope: 'instellingen',
                    section: 'rollen',
                    page: 'Rollen',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                path: 'rollen/uitnodigen',
                name: 'invite-member',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/roles/invite.vue'),
                meta: {
                    title: 'Instellingen',
                    scope: 'instellingen',
                    section: 'uitnodigen',
                    page: 'Nodig gebruiker uit',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                path: 'uitnodigingen',
                name: 'invites',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/settings/invites/invites.vue'),
                meta: {
                    title: 'Instellingen',
                    scope: 'instellingen',
                    page: 'Uitnodigingen',
                    section: 'uitnodigingen',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
            {
                name: 'site-owner-emails',
                path: 'park-eigenaar-emails',
                component: () => import(/* webpackChunkName: "mail-edit" */ '@/pages/email/edit.vue'),
                meta: {
                    scope: 'instellingen',
                    section: 'emails',
                    global: 'setting-base',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },
        ],
    },
    // Bookings
    {
        name: 'booking-base',
        path: '/:siteId-:siteKey/',
        component: () => import(/* webpackChunkName: "booking" */ '@/pages/bookings/base.vue'),
        redirect: { name: 'bookings' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'bookings',
                path: 'boekingen',
                component: () => import(/* webpackChunkName: "excel" */ '@/pages/bookings/overview.vue'),
                meta: { scope: 'boeking', section: 'overzicht', global: 'booking-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'create-booking',
                path: 'boekingen/aanmaken',
                component: () => import(/* webpackChunkName: "booking-create" */ '@/pages/bookings/create.vue'),
                meta: { scope: 'boeking', global: 'booking-base', section: 'nieuwe boeking', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'booking',
                path: 'boekingen/:bookingKey',
                component: () => import(/* webpackChunkName: "booking2" */ '@/pages/bookings/edit.vue'),
                meta: { scope: 'boeking', global: 'booking-base', section: 'boeking', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'bookers',
                path: 'hoofdboekers',
                component: () => import(/* webpackChunkName: "booking" */ '@/pages/bookers/overview.vue'),
                meta: { scope: 'boeking', section: 'hoofdboekers', global: 'booking-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'booker',
                path: 'hoofdboeker/:bookerId',
                component: () => import(/* webpackChunkName: "booking2" */ '@/pages/bookers/edit.vue'),
                meta: { scope: 'boeking', section: 'hoofdboekers', global: 'booking-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'activeguests',
                path: 'huidige-bezoekers',
                component: () => import(/* webpackChunkName: "excel" */ '@/pages/bookings/guests.vue'),
                meta: { scope: 'boeking', section: 'huidige bezoekers', global: 'booking-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'checks',
                path: 'check-in-outs',
                component: () => import(/* webpackChunkName: "excel" */ '@/pages/bookings/checkinsandouts.vue'),
                meta: { scope: 'boeking', section: 'check in/outs', global: 'booking-base', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
        ],
    },
    // Financieel
    {
        name: 'finance-base',
        path: '/:siteId-:siteKey/',
        component: () => import(/* webpackChunkName: "booking" */ '@/pages/payments/base.vue'),
        redirect: { name: 'invoices' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'invoices',
                path: 'facturen',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/payments/invoices.vue'),
                meta: { scope: 'financieel', section: 'Facturen', global: 'financial', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'invoice-create',
                path: 'facturen/bewerk/:invoiceId?',
                component: () => import(/* webpackChunkName: "finance2" */ '@/pages/payments/invoice-create.vue'),
                meta: { scope: 'financieel', section: 'Facturen', global: 'financial', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'invoice',
                path: 'facturen/bekijk/:invoiceId',
                component: () => import(/* webpackChunkName: "finance2" */ '@/pages/payments/invoice-create.vue'),
                meta: { scope: 'financieel', section: 'Facturen', global: 'financial', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },

            {
                name: 'cash-register',
                path: 'losse-verkoop',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/payments/single-sale-create.vue'),
                meta: { scope: 'financieel', section: 'Losse verkoop', global: 'financial', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-finance-accounts',
                path: 'grootboekrekeningen',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/payments/finance-accounts.vue'),
                meta: {
                    scope: 'financieel',
                    section: 'Grootboekrekeningen',
                    global: 'financial',
                    excludedRoles: [UserRoles.RecreapiFrontOffice, UserRoles.RecreapiHomeOwner],
                },
            },

            {
                name: 'payments',
                path: 'betalingen/',
                component: require('./pages/payments/payments').default,
                meta: { scope: 'financieel', global: 'financial', section: 'Financieel', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'site-invoice-data',
                path: 'factuur-gegevens/',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/payments/site-invoice-data.vue'),
                meta: { scope: 'financieel', global: 'financial', section: 'factuurgegevens', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'payment-stats',
                path: 'betaling-statistieken',
                component: () => import(/* webpackChunkName: "finance" */ '@/pages/payments/statistics.vue'),
                meta: { scope: 'financieel', global: 'financial', section: 'statistieken', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
        ],
    },
    {
        name: 'ideal-landing',
        path: '/ideal/:invoicePaymentLinkId',
        component: () => import(/* webpackChunkName: "finance3" */ '@/pages/payments/ideal.vue'),
        meta: { scope: 'betalingen', global: 'payments' },
    },
    // home-owners
    {
        name: 'home-owners-base',
        path: '/:siteId-:siteKey/',
        component: () => import(/* webpackChunkName: "home-owners" */ '@/pages/home-owners/base.vue'),
        redirect: { name: 'home-uniek-owner' },
        meta: { excludedRoles: [UserRoles.RecreapiHomeOwner] },
        children: [
            {
                name: 'home-uniek-owner',
                path: 'eigenaren/overzicht',
                component: () => import(/* webpackChunkName: "home-owner" */ '@/pages/home-owners/home-owners.vue'),
                meta: { scope: 'eigenaren', global: 'home-owners', section: 'overzicht', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'home-owner-accos',
                path: 'eigenaren/accommodaties',
                component: () => import(/* webpackChunkName: "home-owner" */ '@/pages/home-owners/accommodations.vue'),
                meta: { scope: 'eigenaren', global: 'home-owners', section: 'accommodaties', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'home-owner-acco',
                path: 'eigenaren/accommodaties/:accoKey',
                component: () => import(/* webpackChunkName: "home-owner" */ '@/pages/home-owners/accommodation.vue'),
                meta: { scope: 'eigenaren', global: 'home-owners', section: 'accommodatie', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
            {
                name: 'home-owner',
                path: 'eigenaren/:homeOwnerId',
                component: () => import(/* webpackChunkName: "home-owner" */ '@/pages/home-owners/home-owner.vue'),
                meta: { scope: 'eigenaren', global: 'home-owners', section: 'detail', excludedRoles: [UserRoles.RecreapiHomeOwner] },
            },
        ],
    },
    {
        name: 'home-owner-base',
        path: '/eigenarenportaal/',
        component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/base.vue'),
        redirect: { name: 'my-home-owner-accommodations' },
        children: [
            {
                name: 'my-home-owner-accommodations',
                path: 'mijn-huisjes',
                component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/accommodations.vue'),
                meta: { scope: 'eigenaarsportaal', section: 'mijn huisjes', global: 'home-owner-base' },
            },
            {
                name: 'my-home-owner-accommodation',
                path: 'mijn-huisje/:placeId',
                component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/accommodation.vue'),
                meta: { scope: 'eigenaarsportaal', section: 'mijn huisjes', global: 'home-owner-base' },
            },
            {
                name: 'my-home-owner-book-accommodation',
                path: 'mijn-boeking/:placeId',
                component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/book.vue'),
                meta: { scope: 'eigenaarsportaal', section: 'mijn boeking', global: 'home-owner-base' },
            },
            {
                name: 'my-home-owner-invoices',
                path: 'mijn-facturen',
                component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/invoices.vue'),
                meta: { scope: 'eigenaarsportaal', section: 'mijn facturen', global: 'home-owner-base' },
            },
            {
                name: 'my-home-owner-info',
                path: 'mijn-gegevens',
                component: () => import(/* webpackChunkName: "home-owner-portal" */ '@/pages/home-owner-portal/overview.vue'),
                meta: { scope: 'eigenaarsportaal', section: 'mijn gegevens', global: 'home-owner-base' },
            },
        ],
    },
];

const router = new VueRouter({ mode: 'history', routes });

const hasSiteAccess = async (to: Route, user: Member) => {
    if (!roleHelper.isRecreapiHomeOwner()) {
        await sitesModule.fetchSitesIfNeeded();
    }
    await memberModule.fetchMemberIfNeeded();

    const sites = memberModule.member.sites;
    let currentSite = sitesModule.activeSite;

    if (to.params.siteKey) {
        await sitesModule.setActiveSite(
            sites.find((s: Site) => {
                return s.siteKey === to.params.siteKey;
            }),
        );
    } else if (!to.params.siteKey && currentSite.siteId === 0) {
        await sitesModule.setActiveSite(sites[0]);
    }

    currentSite = sitesModule.activeSite;
    const site = user.sites.find((userSite) => {
        return userSite.siteId === sitesModule.activeSite.siteId;
    });

    if (!site) {
        console.error('Not authorized because the user does not own the current site.', { user, currentSite });
        return false;
    }
    return true;
};

const checkRoleAccess = (roles) => {
    let access = true;
    if (!roles) {
        return true;
    }

    roles.forEach((role) => {
        if (roleHelper.userHasRole(role) && !roleHelper.isRecreapiSystemAdmin()) {
            access = false;
        }
    });

    return access;
};

const beforeRouteChanged = async (to: Route, from: Route, next: any) => {
    const anonymousPaths = ['login', 'error', '/ideal/:invoicePaymentLinkId', '/uitnodiging/:key'];
    const homeOwnerPaths = [
        'home',
        'my-home-owner-accommodations',
        'my-home-owner-accommodation',
        'my-home-owner-book-accommodation',
        'my-home-owner-invoices',
        'my-home-owner-info',
        'mijn-huisjes',
        'mijn-huisje',
        'mijn-gegevens',
        'mijn-facturen',
    ];

    const loggedIn = loginHelper.isLoggedIn();
    const matchedRoute = to.matched.length > 0 ? to.matched[0].path : to.path;
    const needAuth = anonymousPaths.indexOf(matchedRoute) < 0 && anonymousPaths.indexOf(to.name) < 0;
    const needSites = homeOwnerPaths.indexOf(matchedRoute) < 0 && homeOwnerPaths.indexOf(to.name) < 0;

    if (!loggedIn && needAuth) {
        return next(`/login?redirectUrl=${to.path}`);
    }

    if (loggedIn) {
        await memberModule.fetchMemberIfNeeded();
    }
    const user = loggedIn ? memberModule.member : null;
    const hasSites = user ? user.sites.length > 0 : false;
    if (needAuth && needSites) {
        if (!hasSites) {
            return next({ name: 'home' });
        }
        const roleAccess = checkRoleAccess(to.meta.excludedRoles);
        const siteAccess = await hasSiteAccess(to, user);

        if (!roleAccess || !siteAccess) {
            return next({ name: 'error', params: { message: 'Geen toegang tot de pagina.' } });
        }
    } else if (needAuth && !needSites && !hasSites) {
        const roleAccess = checkRoleAccess(to.meta.excludedRoles);

        if (!roleAccess) {
            return next({ name: 'error', params: { message: 'Geen toegang tot de pagina.' } });
        }
    }

    next();
};

const onRouteFinished = (to: Route, from: Route) => {
    const meta = to.meta || {};
    Vue.nextTick(() => {
        if (meta.title || meta.scope) {
            document.title = 'Recreapi - ' + (meta.title || meta.scope);
        } else {
            document.title = 'Recreapi';
        }
        navigationHelper.loadRouteMeta(meta, to.params);
    });
};

router.beforeEach(beforeRouteChanged);
router.afterEach(onRouteFinished);

export const $router = router;
export default router;
