import { BaseService } from './baseService';
import Vue from 'vue';
import Product from '../models/Product';

export class ProductService extends BaseService {
    private endpoint = `${Vue.$env().BookingServiceEndpoint}`;
    private params: any[] = [];

    public getProduct(productId: string, siteId?: number) {
        if (siteId) {
            return this.get(`${this.endpoint}/products/:productId?siteId=:siteId`, [
                ['productId', productId],
                ['siteId', siteId],
            ]);
        }

        return this.get(`${this.endpoint}/products/:productId`, [['productId', productId]]);
    }

    public getProducts(siteId?: number, isRegisterApplicable?: boolean, financeAccountId?: number) {
        let querystring = '';
        if (siteId) {
            this.params = [['siteId', siteId]];
            querystring += '?siteId=:siteId';
        }

        if (financeAccountId) {
            this.params.push(['financeAccountId', financeAccountId]);

            if (querystring.indexOf('?') > -1) {
                querystring = '&financeAccountId=:financeAccountId';
            } else {
                querystring = '?financeAccountId=:financeAccountId';
            }
        }

        if (isRegisterApplicable) {
            querystring += '&registerApplicable=:registerApplicable';
            this.params.push(['registerApplicable', isRegisterApplicable.toString()]);
        }

        return this.get(`${this.endpoint}products${querystring}`, this.params);
    }

    public getProductTypes() {
        return this.get(`${this.endpoint}/products/types`, []);
    }

    public saveProduct(product: Product, siteId?: number): Promise<any> {
        if (siteId) {
            return this.post(`${this.endpoint}/products?siteId=:siteId`, product, [['siteId', siteId]]);
        }

        return this.post(`${this.endpoint}/products`, product);
    }

    public updateProduct(product: Product, siteId?: number): Promise<any> {
        if (siteId) {
            return this.put(`${this.endpoint}/products/:productId/?siteId=:siteId`, product, [
                ['siteId', siteId],
                ['productId', product.productId],
            ]);
        }

        return this.put(`${this.endpoint}/products/:productId/`, product, [['productId', product.productId]]);
    }

    public copyProduct(product: Product) {
        return this.post(`${this.endpoint}/products`, product);
    }

    public getRelatedPriceRules(productId: number, siteId?: number) {
        if (siteId) {
            return this.get(`${this.endpoint}price-rules?siteId=:siteId&productId=:productId`, [
                ['productId', productId],
                ['siteId', siteId],
            ]);
        }

        return this.get(`${this.endpoint}price-rules?productId=:productId`, [['productId', productId]]);
    }

    public getVATs() {
        return this.get(`${this.endpoint}/vat`);
    }

    public indexProducts(percentage: number, siteId: number) {
        return this.post(`${this.endpoint}products/index?siteId=:siteId`, { percentage }, [['siteId', siteId]]);
    }

    public deleteProduct(productId: number, siteId?: number) {
        if (siteId) {
            return this.delete(`${this.endpoint}products/:productId?siteId=:siteId`, [
                ['productId', productId],
                ['siteId', siteId],
            ]);
        }

        return this.delete(`${this.endpoint}products/:productId`, [['productId', productId]]);
    }
}
