import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class GridTasksComponent extends Vue {
    public tasks: [] = [];

    public mounted() {
        this.tasks = this.$data.templateArgs.tasks;
    }
}
