import { siteService } from '@/main';
import Site from '../../models/Site';
import to from 'await-to-js';
import { VuexModule, Module, Mutation, Action } from 'vuex-class-modules';
import store from '../index';

@Module
class SiteModule extends VuexModule {
    private _sites: Site[] = [];
    private _activeSite: Site = null;
    private _failed: boolean = false;
    private _failedSite: boolean = false;
    private _loading: boolean = false;

    public get sites() {
        return this._sites;
    }

    public get activeSite() {
        return this._activeSite ? this._activeSite : new Site();
    }

    public get failed() {
        return this._failed;
    }

    public get failedSite() {
        return this._failedSite;
    }

    public get loading() {
        return this._loading;
    }

    @Action
    public async fetchSites() {
        this.SET_LOADING(true);
        const [err, response] = await to(siteService.getSites());
        this.SET_LOADING(false);
        this.SET_FAILED(!!err);
        if (!err) {
            return this.SET_SITES(response.data);
        }
    }

    @Action
    public async fetchSitesIfNeeded() {
        if (!this._sites.length || this._failed) {
            return await this.fetchSites();
        }
    }

    @Action
    public async setActiveSite(site: Site) {
        if (this.activeSite.siteId !== site.siteId) {
            this.SET_LOADING(true);
            const [err, response] = await to(siteService.getSite(site.siteId));
            this.SET_LOADING(false);
            this.SET_FAILED_SITE(!!err);
            if (!err) {
                return this.SET_ACTIVE(response.data);
            } else {
                return this.SET_ACTIVE(site);
            }
        }
    }

    @Mutation
    public SET_ACTIVE(payload: Site) {
        this._activeSite = payload;
    }

    @Mutation
    public CLEAR_SITES() {
        this._sites = [];
        this._failed = false;
    }

    @Mutation
    private SET_SITES(payload: Site[]) {
        this._sites = payload;
    }

    @Mutation
    private SET_FAILED(payload: boolean) {
        this._failed = payload;
    }

    @Mutation
    private SET_FAILED_SITE(payload: boolean) {
        this._failedSite = payload;
    }

    @Mutation
    private SET_LOADING(payload: boolean) {
        this._loading = payload;
    }
}

export const sitesModule = new SiteModule({ store, name: 'sites' });
