import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { VideoInterface } from '@/models/Interfaces';

@Component
export default class VideoList extends Vue {
    @Prop() public source: VideoInterface[];
    @Prop() public editMode: boolean;
}
