import PriceRule from '../PriceRule';

export default class SearchPrice {
    public fullPrice: number = 0;
    public originalDayPriceSum: number = 0;
    public calculatedAccommodationDayPriceSum: number = 0;
    public calculatedPrice: number = 0;
    public additionalCosts: number = 0;
    public optionalProducts: any[] = [];
    public appliedProducts: PriceRule[] = [];
    public dayPrices: any[] = [];
    public totalDays: number = 0;
}
