import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class DebugComponent extends Vue {
    @Prop() public object: any;
    public isVisible: boolean = false;

    public toggleVisibility() {
        this.isVisible = !this.isVisible;
    }
}
