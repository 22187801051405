import Vue from 'vue';
import { BaseService } from './baseService';

export class FinanceAccountService extends BaseService {
    private endpoint = `${Vue.$env().InvoiceServiceEndpoint}`;

    public getAccounts(siteId?: number) {
        if (siteId) {
            return this.get(`${this.endpoint}/finance-account?siteId=:siteId`, [
                ['siteId', siteId],
            ]);
        }

        return this.get(`${this.endpoint}/finance-account`);
    }

    public createFinanceAccount(model: any) {
        return this.post(`${this.endpoint}/finance-account`, model);
    }

    public saveFinanceAccount(model: any) {
        return this.put(`${this.endpoint}/finance-account`, model);
    }
}
