import Enviroment from '@/models/Enviroment';

export default class EnvService {
    public getEnviroment(): Enviroment {
        const browserWindow: any = window || {};
        const env = browserWindow.__env || null;

        if (!env) {
            throw Error('Could not find enviroment variables, make sure env.js exists!');
        }

        return new Enviroment(env);
    }
}
