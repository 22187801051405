import to from 'await-to-js';
import Vue from 'vue';
import { BaseService } from './baseService';

export default class InviteService extends BaseService {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}/invites`;

    public async invites(pending: boolean = false) {
        const [err, response] = await to(this.get(`${this.endpoint}?pending=${pending}`));
        return err ? null : response.data;
    }
}
