import { siteService } from '@/main';
import BasePage from '@/models/BasePage';
import Site from '@/models/Site';
import { memberModule } from '@/store/modules/member';
import { sitesModule } from '@/store/modules/site';
import to from 'await-to-js';
import { AxiosResponse } from 'axios';
import { Component } from 'vue-property-decorator';

@Component
export default class SiteSwitch extends BasePage {
    public isNewLoading: boolean = false;
    public newSiteName: string = '';
    public $refs: {
        newSiteModal: any,
    };

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();
        await memberModule.fetchMember();
    }

    public get member() {
        return memberModule.member;
    }

    public get sites() {
        return this.member ? this.member.sites : [];
    }

    public get activeSite() {
        return new Site(sitesModule.activeSite);
    }

    public showAddSiteModal() {
        this.$refs.newSiteModal.show();
    }

    public async switchTo(site: Site) {
        const siteKey = site.siteKey;
        let name = this.$route.name;
        if (name === 'error' || name === 'home') {
            name = 'site';
        }
        await sitesModule.setActiveSite(site);
        this.$router.push({ name, params: { siteId: site.siteId.toString(), siteKey } });
    }

    public async addNewSite() {
        this.isNewLoading = true;
        const site = {
            facilities: [],
            videos: [],
            images: [],
            name: this.newSiteName,
            location: {},
        } as Site;

        const [err, response] = await to<AxiosResponse<Site>>(siteService.createOrUpdateSite(site));
        if (err) {
            this.showFailedResponse('Park aanmaken mislukt', err);
            this.isNewLoading = false;
            return;
        }

        const newSite = response.data as Site;
        await memberModule.fetchMember();
        this.$refs.newSiteModal.hide();
        this.isNewLoading = false;
        this.$router.push({ name: 'site-base', params: { siteId: newSite.siteId.toString(), siteKey: newSite.siteKey } });
    }
}
