import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridButtonComponent extends Vue {
    @Prop({ default: null }) public callback: CallableFunction;
    @Prop({ default: null }) public arg: any;
    @Prop({ default: '' }) public text: string;
    @Prop({ default: null }) public icon: string;

    public onClick() {
        if (this.callback) {
            this.callback(this.arg);
        }
    }
}
