import { loginService } from '@/main';
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

export abstract class BaseService {
    private get authAxiosInstance(): AxiosInstance {
        return loginService.authAxiosInstance;
    }

    public get<T = any>(url: string, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<T> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.get<T>(url, config);
    }

    public delete(url: string, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<any> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.delete(url, config);
    }

    public head(url: string, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<any> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.head(url, config);
    }

    public post<T = any>(url: string, data?: any, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<T> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.post<T>(url, data, config);
    }

    public put<T = any>(url: string, data?: any, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<T> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.put<T>(url, data, config);
    }

    public patch<T = any>(url: string, data?: any, parameters?: ReadonlyArray<[string, any]>, config?: AxiosRequestConfig): AxiosPromise<T> {
        url = this.bindParameters(url, parameters);
        return this.authAxiosInstance.patch<T>(url, data, config);
    }

    private valueInvalid(value: any): boolean {
        return value === undefined || value === null;
    }

    private bindParameters(url: string, parameters?: ReadonlyArray<[string, any]>): string {
        const parametersMap = new Map<string, any>(parameters);
        if (parametersMap === undefined || parametersMap === null) {
            return url;
        }

        const params = url.match(/:{1}(\??([a-z|A-Z]+))/gm);
        if (!params || params.length === 0) {
            return url;
        }

        for (const p of params) {
            let key = p.slice(1);
            const isOptional = key.startsWith('?');

            if (isOptional) {
                key = key.slice(1);
            } else if (!parametersMap.has(key)) {
                throw new Error(`Required parameter with key ${key} is not in the parameters map.`);
            }

            const value = parametersMap.get(key);

            if (this.valueInvalid(value)) {
                if (isOptional) {
                    url = url.replace(p, '');
                    continue;
                } else {
                    throw new Error(`Parameter with key ${key} is 'undefined' or 'null'.`);
                }
            }

            url = url.replace(p, value);
        }

        return url;
    }
}
