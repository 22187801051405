import Vue from 'vue';
import { BaseService } from './baseService';
import Facility from '../models/Facility';
import FacilityGroup from '../models/FacilityGroup';

export class FacilityService extends BaseService {
    private facilityEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'facilities';
    private facilityGroupEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'facility-groups';

    public async createOrUpdateFacilityGroup(model: FacilityGroup) {
        return this.post<FacilityGroup>(`${this.facilityGroupEndpoint}`, model);
    }

    public async createOrUpdateFacility(model: Facility, facilityGroupId: number) {
        return this.post<Facility>(`${this.facilityEndpoint}/:facilityGroup`, model, [['facilityGroup', facilityGroupId]]);
    }

    public getFacilities(siteKey?: string, onlySystemFacilities: boolean = false) {
        if (siteKey) {
            return this.get(`${this.facilityEndpoint}?siteKey=:siteKey&onlySystemFacilities=${onlySystemFacilities}`, [['siteKey', siteKey]]);
        }

        return this.get(`${this.facilityEndpoint}?onlySystemFacilities=${onlySystemFacilities}`);
    }

    public getFacilityGroups(siteKey?: string) {
        if (siteKey) {
            return this.get(`${this.facilityGroupEndpoint}?siteKey=:siteKey`, [['siteKey', siteKey]]);
        }

        return this.get<FacilityGroup[]>(`${this.facilityGroupEndpoint}`);
    }

    public getGroupFacilities(group: string) {
        return this.get<Facility[]>(`${this.facilityEndpoint}/:group`, [['group', group]]);
    }

    public deleteFacility(id: number) {
        return this.delete(`${this.facilityEndpoint}?id=:id`, [['id', id]]);
    }

    public deleteFacilityGroup(model: FacilityGroup) {
        return this.delete(`${this.facilityGroupEndpoint}/:facilityGroupId`, [['facilityGroupId', model.facilityGroupId]]);
    }
}
