/**
 * Different types of roles used within the application.
 */
export enum UserRoles {

    /**
     * Has global wide 'superuser' rights.
     */
    SiteAdmin = 'SiteAdmin',

    /**
     * Has rights on an account with sites (parks).
     */
    RecreapiSystemAdmin = 'RecreapiSystemAdmin',

    /**
     * Has rights for a site.
     */
    RecreapiSiteAdmin = 'RecreapiSiteAdmin',

    /**
     * Has rights on specific pages of a site.
     */
    RecreapiFrontOffice = 'RecreapiFrontOffice',

    /**
     * Has rights on the home onwer portal.
     */
    RecreapiHomeOwner = 'RecreapiHomeOwner',
}

/**
 * Target specific role definitions for users.
 */
export default class UserRole {
    public id: number;
    public role: UserRoles;
}
