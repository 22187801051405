import Vue from 'vue';
import { BaseService } from './baseService';
import { SearchPlaceTypeParamsInterface } from '../models/Interfaces';
import { AxiosPromise } from 'axios';
import SearchStatistics from '../models/SearchStatistics';

export class SearchService extends BaseService {
    private endpoint = Vue.$env().SearchOptimizerEndpoint;
    private statisticsEndpoint = Vue.$env().SearchServiceEndpoint;

    public accommodation(searchParams: SearchPlaceTypeParamsInterface, config?) {
        const url = `search2?${this.parseSearchStringParams(searchParams)}`;
        return this.get(`${this.endpoint}${url}`, [], config);
    }

    public map(searchParams: SearchPlaceTypeParamsInterface, config?) {
        const url = `map?${this.parseSearchStringParams(searchParams)}`;
        return this.get(`${this.endpoint}${url}`, [], config);
    }

    public facets(searchParams: SearchPlaceTypeParamsInterface, config?) {
        return this.get(
            `${this.endpoint}search2?siteId=:?site&siteOwner=:siteOwner`,
            [
                ['site', searchParams.site],
                ['siteOwner', searchParams.siteOwner],
            ],
            config,
        );
    }

    public getSearchStatistics(): AxiosPromise<SearchStatistics> {
        return this.get<SearchStatistics>(`${this.statisticsEndpoint}/healthz/statistics`);
    }

    private parseSearchStringParams(searchParams: SearchPlaceTypeParamsInterface): string {
        const params: string[] = [
            `siteId=${searchParams.site}`,
            `siteOwner=${searchParams.siteOwner}`,
            searchParams.facets,
        ];

        if (searchParams.fromDate) {
            params.push(`fromDate=${searchParams.fromDate}`);
        }

        if (searchParams.toDate) {
            params.push(`toDate=${searchParams.toDate}`);
        }

        if (searchParams.fromCapacity) {
            params.push(`fromCapacity=${searchParams.fromCapacity}`);
        }

        if (searchParams.toCapacity) {
            params.push(`toCapacity=${searchParams.toCapacity}`);
        }

        if (searchParams.Adult > 0) {
            params.push(`adult=${searchParams.Adult}`);
        }

        if (searchParams.Senior > 0) {
            params.push(`senior=${searchParams.Senior}`);
        }

        if (searchParams.ZeroUntilTwoYears > 0) {
            params.push(`zeroUntilTwoYears=${searchParams.ZeroUntilTwoYears}`);
        }

        if (searchParams.ChildThreeYearsAndUp > 0) {
            params.push(`childThreeYearsAndUp=${searchParams.ChildThreeYearsAndUp}`);
        }

        if (searchParams.Pet > 0) {
            params.push(`pet=${searchParams.Pet}`);
        }

        if (searchParams.disabledAccessible) {
            params.push('toegankelijkheid_facet=Minder valide woning');
        }

        if (searchParams.petsAllowed) {
            params.push('toegankelijkheid_facet=Huisdieren toegestaan');
        }

        return params.filter((param: string) => !!param).join('&');
    }
}
