import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component
export default class Spinner extends Vue {
    @Prop() public text: string;
    @Prop({ default: false }) public absolute: boolean;
    @Prop({ default: true }) public icon: boolean;

    public getStyle() {
        return this.absolute ? `
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                background: rgba(255, 255, 255, 0.5);
        ` : '';
    }
}
