import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { VideoInterface } from '../../models/Interfaces';

@Component
export default class Video extends Vue {
    @Prop() public video: VideoInterface;
    @Prop() public index: number;
    @Prop() public editMode: boolean;
    @Prop() public source: VideoInterface[];

    public deleteVideoFromSite(index) {
        this.source.splice(index, 1);
    }

    public getYoutubeThumbnail(video): string {
        if (video.videoType === 'YouTube') {
            const start = video.url.indexOf('v=');
            const end = video.url.indexOf('&') > -1 ? video.url.indexOf('&') : video.url.length;
            let id = '';

            if (start > -1) {
                id = video.url.substring(start + 2, end);
                return `https://img.youtube.com/vi/${id}/0.jpg`;
            }
        }
        return '';
    }
}
