import { UserRoles } from '../models/UserRoles';
import { loginHelper } from '@/main';

/**
 * Helper function to assist in checking user roles.
 */
export class RoleHelper {
    /**
     * Check if a user belongs to a role for the target.
     * @param role Role to check.
     */
    public userHasRole(role: UserRoles) {
        const roles = loginHelper.getRoles();
        const foundRole = roles.find((item) => {
            return item === role;
        });
        return typeof foundRole !== 'undefined';
    }

    public isRecreapiSystemAdmin(): boolean {
        return this.userHasRole(UserRoles.RecreapiSystemAdmin);
    }

    public isRecreapiFrontOffice(): boolean {
        return this.userHasRole(UserRoles.RecreapiFrontOffice);
    }

    public isRecreapiSiteAdmin(): boolean {
        return this.userHasRole(UserRoles.RecreapiSiteAdmin);
    }

    public isSiteAdmin(): boolean {
        return this.userHasRole(UserRoles.SiteAdmin);
    }

    public isRecreapiHomeOwner(): boolean {
        return this.userHasRole(UserRoles.RecreapiHomeOwner);
    }
}
