import Vue from 'vue';
import { BaseService } from './baseService';
import Booker from '../models/Booker';
import { AxiosPromise } from 'axios';

export class BookerService extends BaseService {
    private endpoint = `${Vue.$env().BookingServiceEndpoint}` + 'bookers';

    public getBookers(siteId: number, includeDeleted: boolean = false): AxiosPromise<Booker[]> {
        let url = '?siteId=:siteId';
        if (includeDeleted) {
            url += '&include_deleted=true';
        }

        return this.get<Booker[]>(`${this.endpoint}${url}`, [['siteId', siteId]]);
    }

    public createNewBooker(model: any, siteId: number) {
        return this.post<Booker>(`${this.endpoint}?siteId=:siteId`, model, [['siteId', siteId]]);
    }

    public getBooker(id: string, siteId: number) {
        return this.get(`${this.endpoint}/:id?siteId=:siteId`, [
            ['id', id],
            ['siteId', siteId],
        ]);
    }

    public saveBooker(booker: Booker, siteId: number) {
        return this.post(`${this.endpoint}/?siteId=:siteId`, booker, [['siteId', siteId]]);
    }

    public updateBooker(booker: Booker, siteId: number) {
        return this.put(`${this.endpoint}/?siteId=:siteId`, booker, [['siteId', siteId]]);
    }

    public mergeBookers(slaveMainBookerId: number, masterMainBookerId: number, siteId: number): Promise<unknown> {
        return this.post(`${this.endpoint}/merge`, {
            masterMainBookerId,
            slaveMainBookerId,
        });
    }

    public archiveBooker(bookerId: number, siteId: number) {
        return this.delete(`${this.endpoint}/${bookerId}?siteId=:siteId`, [['siteId', siteId]]);
    }
}
