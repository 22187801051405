import StatisticsFinanceGroup from '@/models/Statistics/FinanceGroup';
import StatisticsGroup from '@/models/Statistics/Group';
import StatisticsParams from '@/models/Statistics/Params';
import StatisticsResponse from '@/models/Statistics/Response';
import Vue from 'vue';
import { BaseService } from './baseService';

export class StatsService extends BaseService {
    private bookingsEndpoint = `${Vue.$env().BookingServiceEndpoint}statistics/bookings`;
    private financesEndpoint = `${Vue.$env().InvoiceServiceEndpoint}statistics/finance`;

    public async bookings(params: StatisticsParams) {
        const response = await this.get(`${this.bookingsEndpoint}?${this.parseParams(params)}`);
        return response.data as StatisticsResponse;
    }

    public async finance(params: StatisticsParams) {
        const response = await this.get(`${this.financesEndpoint}?${this.parseParams(params)}`);
        response.data.groups = response.data.groups.map((group: StatisticsFinanceGroup) => {
            return {
                groupKey: group.financeAccount,
                groupCount: (Math.round(group.totalAmount * 100) / 100).toFixed(2),
            } as StatisticsGroup;
        }) as StatisticsResponse;
        return response.data;
    }

    private parseParams(params: StatisticsParams): string {
        const args = [
            `site_id=${params.siteId}`,
            `from_date=${params.fromDate}`,
            `to_date=${params.toDate}`,
        ];

        if (params.groupBy) {
            args.push(`group_by=${params.groupBy}`);
        }

        if (params.state) {
            args.push(`booking_state=${params.state}`);
        }

        Object.keys(params).forEach((key: string) => {
            const ignore = ['siteId', 'fromDate', 'toDate', 'groupBy', 'state'];
            if (!ignore.includes(key)) {
                args.push(`${key}=${params[key]}`);
            }
        });

        return args.join('&');
    }
}
