import Vue from 'vue';
import { BaseService } from './baseService';
import RecreapiEvent from '../models/RecreapiEvent';
import EventType from '../models/EventType';

export class CalendarService extends BaseService {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;
    private placeTypeEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;

    public getPlaceCalendar(place: any) {
        return this.get<RecreapiEvent[]>(`${this.endpoint}places/:place/calendar`, [
            ['place', place],
        ]);
    }

    public saveOccupation(model, place: number, siteKey: string, from: any, to: any) {
        return this.post(`${this.endpoint}places/:place/occupation?siteKey=:siteKey`, model, [
            ['place', place],
            ['siteKey', siteKey],
        ]);
    }

    public removeOccupation(place: number, siteKey: string, from: any, to: any) {
        return this.delete(`${this.endpoint}places/:place/occupation?siteKey=:siteKey&startDate=:startDate&endDate=:endDate`, [
            ['place', place],
            ['siteKey', siteKey],
            ['startDate', from],
            ['endDate', to],
        ]);
    }

    public getPlaceTypeCalendar(placeTypeKey: string) {
        return this.get<RecreapiEvent[]>(`${this.placeTypeEndpoint}place-types/:place/calendar`, [
            ['place', placeTypeKey],
        ]);
    }

    public saveBankHolidays(model: any[]) {
        return this.post<any>(`${this.endpoint}events`, model);
    }

    public deleteBankHolidays(model: any[]) {
        return this.delete(`${this.endpoint}events?ids=:ids`, [
            ['ids', model],
        ]);
    }

    public getTypes() {
        return this.get<EventType[]>(`${this.endpoint}/event-types`);
    }
}
