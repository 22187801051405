import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import { discountService } from '@/main';
import Site from '../../../../models/site';
import to from 'await-to-js';
import { Watch } from 'vue-property-decorator';
import { sitesModule } from '@/store/modules/site';

@Component({
    name: 'Discountcode',
})
export default class Discount extends BasePriceRuleComponent {
    public discountGroups: any[] = [];
    public site: Site = {} as Site;
    public selectedDiscountGroup: any = null;
    public systemScope: boolean = false;

    @Watch('selectedDiscountGroup', { deep: true, immediate: true })
    public OnDiscountGroupChange(val, oldVal) {
        if (val) {
            this.condition.discountGroupId = val.discountGroupId;
            this.condition.discountGroup = val;
        }
    }

    public async mounted() {
        this.systemScope = typeof this.$route.params.siteKey === 'undefined';
        await sitesModule.fetchSitesIfNeeded();

        if (!this.systemScope) {
            this.site = sitesModule.sites.find((x) => x.siteKey === this.siteKey);
        }

        await this.loadDiscounts();
    }

    public async loadDiscounts() {
        const [err, response] = await to(discountService.getDiscounts(this.systemScope ? null : this.site.siteId));
        if (err) {
            this.showError('Kortingscodes ophalen mislukt');
        }
        return this.discountGroups = response.data;
    }
}
