import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class SiteFacilityComponent extends Vue {
    public facility: any = {};

    public mounted() {
        this.facility = this.$data.templateArgs.facility;
    }
}
