import Component from 'vue-class-component';
import Vue from 'vue';
import { navigationHelper } from '@/main';

@Component
export default class BarComponent extends Vue {
    public toggleNavMenu() {
        navigationHelper.toggleNavigation();
    }
}
