import Component from 'vue-class-component';
import SideActionBase from '@/plugin/sideActions/sideActionBase';
import to from 'await-to-js';
import { Prop } from 'vue-property-decorator';
import { financeAccountService, homeOwnerService, productService } from '@/main';
import AnnualInvoice, { InvoiceLine } from '@/models/AnnualInvoice';
import { sitesModule } from '@/store/modules/site';
import { memberModule } from '@/store/modules/member';

@Component
export default class AnnualInvoiceModelSiteAction extends SideActionBase<any> {
    @Prop({ default: () => new AnnualInvoice() }) public annualInvoice: AnnualInvoice;
    public localAnnualInvoice: AnnualInvoice = new AnnualInvoice();
    public isEdit: boolean = false;
    public isLoading: boolean = true;
    public submitted: boolean = false;
    public renderTable: number = 0;
    public financeAccounts: any[] = [];

    public vats: any[] = [];

    public async created() {
        this.isEdit = !!this.options.annualInvoiceId;
        this.financeAccounts = (await financeAccountService.getAccounts()).data;
        await this.loadVATs();

        if (this.isEdit) {
            this.localAnnualInvoice = (await homeOwnerService.getAnnualInvoice(this.options.annualInvoiceId)).data;

            this.localAnnualInvoice.invoiceLines.forEach((x) => {
                x.financeAccount = this.financeAccounts.find((y) => y.financeAccountId === x.financeAccountId);
            });

            this.localAnnualInvoice.invoiceLines.forEach((x) => {
                x.vat = this.vats.find((y) => y.percentage === x.vatPercentage);
            });
        }

        if (!this.localAnnualInvoice.invoiceLines || this.localAnnualInvoice.invoiceLines.length === 0) {
            this.addInvoiceLine();
        }

        this.isLoading = false;
    }

    public resetOrganization() {
        this.localAnnualInvoice = new AnnualInvoice();
    }

    public homeOWnerCreated(e) {
        this.finished(e);
    }

    public addInvoiceLine() {
        this.localAnnualInvoice.invoiceLines.push(new InvoiceLine());
        this.renderTable++;
    }

    public deleteInvoiceLine(index) {
        this.localAnnualInvoice.invoiceLines.splice(index, 1);
    }

    public financeAccountsOptions() {
        const list = [{ financeAccountId: null, name: 'Selecteer grootboek' }];
        list.push(...this.financeAccounts);
        return list;
    }

    public async save() {
        this.showPending('Jaarfactuur model opslaan..');

        this.localAnnualInvoice.invoiceLines.forEach((x) => {
            x.financeAccountId = x.financeAccount.financeAccountId;
        });

        if (this.isEdit) {
            const [err] = await to(homeOwnerService.updateAnnualInvoiceModal(this.localAnnualInvoice));
            if (err) {
                this.clearAndShowError('Mislukt om model op te slaan');
                return;
            }
        } else {
            this.localAnnualInvoice.placeId = parseInt(this.$route.params.accoKey, 10);
            this.localAnnualInvoice.siteId = sitesModule.activeSite.siteId;
            this.localAnnualInvoice.siteOWnerId = memberModule.member.siteOwnerIds[0];

            const [err, response] = await to(homeOwnerService.createAnnualInvoiceModal(this.localAnnualInvoice));
            if (err) {
                this.clearAndShowError('Mislukt om model op te slaan');
                return;
            }
            this.annualInvoice.annualInvoiceId = response.data.annualInvoiceId;
        }

        this.clearAndShowSuccess('Jaarfactuur model opgeslagen');
        this.finished(this.annualInvoice);
    }

    public async loadVATs() {
        const [err, response] = await to(productService.getVATs());
        if (err) {
            this.showError('BTW-schalen ophalen mislukt');
        }
        return (this.vats = response.data);
    }

    public selectVat(vat, index) {
        this.localAnnualInvoice.invoiceLines[index].vatPercentage = vat.percentage;
    }
}
