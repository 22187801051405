import Site from './site';

export class Member {
    public id: number = 0;
    public userId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public insertion: string = '';
    public email: string = '';
    public externalUserId: number = null;
    public siteIds: number[];
    public siteOwnerIds: number[];
    public sites: Site[] = [];
    public roles: any[] = [];

    constructor(member: any = {}) {
        Object.assign(this, member);
    }

    public getFullName(): string {
        const parts = [this.firstName, this.insertion, this.lastName];
        parts.filter((value) => !!value);

        return parts.join(' ');
    }
}
