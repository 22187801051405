import { ImageInterface, VideoInterface } from './Interfaces';
import { SiteLocation } from './SiteLocation';
import Facility from './Facility';

export default class Site {
   public  siteId: number = 0;
   public  siteKey: string = '';
   public  name: string = '';
   public  siteName: string = '';
   public  description: string = '';
   public  nearby: string = '';

   public  standardCheckInTime: string = '';
   public  standardCheckOutTime: string = '';

   public  contactPhoneNumber: string = '';
   public  contactEmailaddress: string = '';

   public  facilities: Facility[];
   public  location: SiteLocation;
   public  images: ImageInterface[];
   public  videos: VideoInterface[];

   constructor(obj: any = {}) {
      Object.assign(this, obj);
   }
}
