import BasePriceRuleComponent from './base';
import Component from 'vue-class-component';
import moment from 'moment/min/moment.min.js';
import { Watch } from 'vue-property-decorator';

@Component({
    name: 'DateRange',
})
export default class DateRange extends BasePriceRuleComponent {
    public fromDate: Date = null;
    public toDate: Date = null;

    @Watch('toDate')
    public onToDateChange(val, oldVal) {
        if (val) {
            const date = moment(val).format('YYYY-MM-DD');
            this.condition.toDate = date;
        }
    }
    @Watch('fromDate')
    public onFromDateChange(val, oldVal) {
        if (val) {
            const date = moment(val).format('YYYY-MM-DD');
            this.condition.fromDate = date;
        }
    }

    public created() {
        if (this.condition.fromDate) {
            this.fromDate = moment(this.condition.fromDate).toDate();
        }

        if (this.condition.toDate) {
            this.toDate = moment(this.condition.toDate).toDate();
        }
    }
}
