import { ImageInterface, VideoInterface, PlaceInterface } from './Interfaces';
import PropertyType from './PropertyType';
import PlaceType from './PlaceType';
import HomeOwner from './HomeOwner';

export default class Place extends PlaceType implements PlaceInterface {
    public annualInvoiceId: number;
    public placeId: number;
    public placeTypeId: number = 0;
    public placeTypeKey: string = '';
    public placeType: PlaceType;
    public name: string = '';
    public description: string = '';
    public layout: string = '';
    public capacity: number = 2;
    public classification: string = '';
    public lotNumber: string;
    public longitude: number;
    public latitude: number;
    public propertyType: PropertyType = null;
    public features: any = [];
    public placeTypeFeatures: any;
    public siteId: number;
    public propertyValue: number;
    public desiredROI: number;
    public commissionRate: number;
    public homeOwnerId: number;
    public homeOwner: HomeOwner;
    public electricity: number;
    public gas: number;
    public water: number;
    public rentCosts: number;
    public serviceCosts: number;
    public rentalState: string;
    public plotType: string = 'Default';

    public isPromoted: boolean = false;

    public images: ImageInterface[] = [];
    public videos: VideoInterface[] = [];

    constructor(obj: any = {}) {
        super(obj);
        Object.assign(this, obj);
    }
}
