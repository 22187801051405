import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PageRender from '../../../../models/PageRender';
import { dictionaryHelper } from '@/main';

@Component
export default class BasePriceRuleComponent extends PageRender {
    @Prop() public condition: any;
    public dictionary = dictionaryHelper;

    public created() {
        if (!this.condition.hasOwnProperty('shouldMatch')) {
            this.condition.shouldMatch = true;
        }
    }
}
