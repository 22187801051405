import Vue from 'vue';
import { BaseService } from './baseService';

export class DiscountService extends BaseService {
    private endpoint = `${Vue.$env().BookingServiceEndpoint}`;

    public getDiscounts(siteId?: number) {
        if (siteId) {
            return this.get(`${this.endpoint}/discount?siteId=:siteId`, [['siteId', siteId]]);
        }

        return this.get(`${this.endpoint}/discount`);
    }

    public getDiscount(discountId: number, siteId?: number) {

        if (siteId) {
            return this.get(`${this.endpoint}discount/:discountId?siteId=:siteId`, [['siteId', siteId], ['discountId', discountId]]);
        }

        return this.get(`${this.endpoint}discount/:discountId`, [['discountId', discountId]]);
    }

    public addCampgaign(newCampaignModel: any, siteId?: number): Promise<any> {
        if (siteId) {
            return this.post(`${this.endpoint}discount?siteId=:siteId`, newCampaignModel, [['siteId', siteId]]);
        }

        return this.post(`${this.endpoint}discount`, newCampaignModel);
    }

    public deactivateCode(discountCodeId: any, siteId?: any): Promise<any> {
        if (siteId) {
            return this.put(`${this.endpoint}discount/:discountCodeId/deactivate?siteId=:siteId`, null, [['siteId', siteId], ['discountCodeId', discountCodeId]]);
        }

        return this.put(`${this.endpoint}discount/:discountCodeId/deactivate`, null, [['discountCodeId', discountCodeId]]);

    }

    public updateCode(codeModel: any, siteId?: number): Promise<any> {
        if (siteId) {
            return this.put(`${this.endpoint}discount/:discountGroupId/code?siteId=:siteId`, codeModel, [['siteId', siteId], ['discountGroupId', codeModel.discountCodeId]]);
        }

        return this.put(`${this.endpoint}discount/:discountGroupId/code`, codeModel, [['discountGroupId', codeModel.discountCodeId]]);

    }

    public updateGroup(campaign: any, siteId?: number): Promise<any> {
        if (siteId) {
            return this.put(`${this.endpoint}discount/:discountGroupId?siteId=:siteId`, campaign, [['siteId', siteId], ['discountGroupId', campaign.discountGroupId]]);
        }

        return this.put(`${this.endpoint}discount/:discountGroupId`, campaign, [['discountGroupId', campaign.discountGroupId]]);

    }

    public addCodesToCampgaign(newCampaignModel: any, siteId?: number): Promise<any> {
        if (siteId) {
            return this.put(`${this.endpoint}discount/:discountGroupId?siteId=:siteId`, newCampaignModel, [['siteId', siteId], ['discountGroupId', newCampaignModel.discountGroupId]]);
        }

        return this.put(`${this.endpoint}discount/:discountGroupId`, newCampaignModel, [['discountGroupId', newCampaignModel.discountGroupId]]);

    }

    public copyCampaign(newCampaignModel: any, siteId: number): Promise<any> {
        return this.post(`${this.endpoint}discount/:discountGroupId/copy?siteId=:siteId`, newCampaignModel, [['siteId', siteId], ['discountGroupId', newCampaignModel.discountGroupId]]);
    }
}
