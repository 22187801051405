import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { ImageInterface } from '../../models/Interfaces';

@Component
export default class ImageList extends Vue {
    @Prop() public source: ImageInterface[];
    @Prop() public editMode: boolean;
    @Prop() public image: ImageInterface;
    @Prop() public index: number;

    public deleteImage(index) {
        this.source.splice(index, 1);
    }
}
