import { BaseService } from './baseService';
import Vue from 'vue';

export class SitePrecenseService extends BaseService {
    private endpoint = Vue.$env().BookingServiceEndpoint;

    public getSitePrecense(siteId: number) {
        return this.get(`${this.endpoint}/site-presence?siteId=:siteId`, [['siteId', siteId]]);
    }
}
