import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Invoice } from '../../models/Invoice';
import moment from 'moment/min/moment.min.js';

@Component
export default class GridPopoverComponent extends Vue {
    public invoice: Invoice = {} as Invoice;
    public grid: string = '';

    public mounted() {
        this.invoice = this.$data.templateArgs.invoice;
        this.grid = this.$data.templateArgs.grid;
    }

    public getDate(): string {
        return moment(this.invoice.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }
}
