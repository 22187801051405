import Site from '../models/Site';
import { sitesModule } from '@/store/modules/site';
import { memberModule } from '@/store/modules/member';

export class SiteHelper {
    public sites: Site[] = [];
    public userSites: Site[] = [];
    public currentSite: Site = null;
    public error: any = null;
    public response: any = null;

    public clearSites() {
        this.sites = [];
        this.currentSite = null;
    }

    public async getUserSites(force = false) {
        if (!force && this.userSites.length > 0) {
            return this.userSites;
        }
        await memberModule.fetchMemberIfNeeded();
        this.userSites = memberModule.member.sites;
        return this.userSites;
    }

    public setCurrentSite(siteKey: string, sites) {
        this.currentSite = sites.find((site) => {
            return site.siteKey === siteKey;
        });
        return this.currentSite;
    }

    public setCurrentSiteObject(site: Site) {
        this.currentSite = site;
    }

    public getCurrentSite() {
        return this.currentSite || new Site();
    }

    public getCurrentSiteKey() {
        const currentSite = this.getCurrentSite();
        return currentSite.siteKey;
    }

    public contains(siteKey: string, sites) {
        return !!sites.find((site) => {
            return site.siteKey === siteKey;
        });
    }

    public async setSiteIdAsCurrentSite(siteId: number) {
        await sitesModule.fetchSitesIfNeeded();
        const site = sitesModule.sites.find((s: Site) => {
            return s.siteId === siteId;
        });

        return (this.currentSite = site);
    }
}
