import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { dictionaryHelper } from '@/main';

@Component
export default class DaysInWeek extends Vue {
    @Prop() public value: any;
    public dictionary = dictionaryHelper;

    public $refs!: {
        daySelect: any,
    };

    public onChanged() {
        this.$emit('input', this.$refs.daySelect.value);
    }
}
