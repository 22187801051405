import Vue from 'vue';
import { BaseService } from './baseService';
import Feature from '../models/Feature';

export class FeatureService extends BaseService {
    private featureEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'features';
    private featureGroupsEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'feature-groups';

    public async createOrUpdateFeature(model: Feature) {
        return this.post<Feature>(`${this.featureEndpoint}`, model);
    }

    public getFeatures() {
        return this.get<Feature[]>(`${this.featureEndpoint}`);
    }

    public getFeatureGroups() {
        return this.get<any[]>(`${this.featureGroupsEndpoint}`);

    }

    public getFeature(site: string) {
        return this.get<Feature>(`${this.featureEndpoint}/:site`, [
            ['site', site],
        ]);
    }
    public deleteFeature(id: number) {
        return this.delete(`${this.featureEndpoint}?id=:id`, [
            ['id', id],
        ]);
    }
}
