import { BaseService } from './baseService';
import Vue from 'vue';

export class MediaService extends BaseService {
    private endpoint = Vue.$env().MediaServiceEndpoint;

    public uploadImage(file) {
        const formData = new FormData();
        const headers = { 'Content-Type': 'multipart/form-data' };

        formData.append('file', file);
        return this.post(`${this.endpoint}/image/upload`, formData, [], { headers });
    }
}
