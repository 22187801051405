import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import { bookingService } from '@/main';

@Component({
    name: 'NumberOfTravellers',
})
export default class NumberOfTravellers extends BasePriceRuleComponent {
    public types: any[] = [];

    public async mounted() {
        this.types = bookingService.getTravelGroupTypes();
    }
}
