import Component from 'vue-class-component';
import BasePriceRuleViewComponent from './base';
import Site from '../../../../models/site';
import { discountService } from '@/main';
import to from 'await-to-js';
import { sitesModule } from '@/store/modules/site';

@Component({
    name: 'DiscountCodeView',
})
export default class DiscountCodeView extends BasePriceRuleViewComponent {
    public discountGroups: any[] = [];
    public site: Site = {} as Site;
    public systemScope: boolean = false;
    public async created() {
        this.systemScope = typeof this.$route.params.siteKey === 'undefined';
        await sitesModule.fetchSitesIfNeeded();

        if (!this.systemScope) {
            this.site = sitesModule.sites.find((x) => x.siteKey === this.siteKey);
        }

        await this.loadDiscounts();
    }

    public async loadDiscounts() {
        const [err, response] = await to(discountService.getDiscounts(this.systemScope ? null : this.site.siteId));
        if (err) {
            this.showError('Kortingscodes ophalen mislukt');
        }
        return this.discountGroups = response.data;
    }

    public getMarketingName(id) {
        const discountGroup = this.discountGroups.find((group) => group.discountGroupId === id);
        if (discountGroup) {
            return discountGroup.name;
        }

        return '';
    }
}
