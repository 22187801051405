import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { SearchPlaceTypeParamsInterface } from '../../../models/Interfaces';
import { placeSearchHelper } from '@/main';

@Component
export default class PlaceTypeSearchDate extends Vue {
    @Prop() public period: {
        fromDate: Date,
        toDate: Date,
    };
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;

    @Watch('period', { deep: true })
    public watchPeriod(val, old) {
        placeSearchHelper.setSearchParamsPeriod(this.searchParams, this.period.fromDate, this.period.toDate);
    }

    public get minToDate() {
        const from = new Date(this.period.fromDate);
        return new Date(from.setDate(from.getDate() + 1));
    }
}
