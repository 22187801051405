import { BaseService } from './baseService';
import Vue from 'vue';
import { AxiosPromise } from 'axios';
import to from 'await-to-js';
import { UserRoles } from '@/models/UserRoles';

export default class UserRoleService extends BaseService {
    private endpoint = `${Vue.$env().IdentityEndpoint}api/roles/`;
    private addDeleteEndpoint = `${this.endpoint}user/:personId/role/:role`;

    public async getPermissions() {
        return [
            { id: 'RecreapiSystemAdmin', description: 'Systeem Administrator' },
            { id: 'RecreapiSiteAdmin', description: 'Park Administrator' },
            { id: 'RecreapiFrontOffice', description: 'Front-office medewerker' },
            // { id: 'RecreapiHomeOwner', description: 'Huiseigenaar' },
        ];
    }

    public getUsersInRole(role: UserRoles): AxiosPromise<any[]> {
        return this.get(`${this.endpoint}:role/users`, [['role', role]]);
    }

    public makeAddDeleteData(personId: number, role: UserRoles): ReadonlyArray<[string, any]> {
        return [
            ['personId', personId],
            ['role', role as string],
        ];
    }

    public setUserRole(personId: number, role: UserRoles) {
        return this.post(this.addDeleteEndpoint, null, this.makeAddDeleteData(personId, role));
    }

    public deleteUserRole(personId: number, role: UserRoles) {
        return this.delete(this.addDeleteEndpoint, this.makeAddDeleteData(personId, role));
    }
}
