import Vue from 'vue';

export default class PageRender extends Vue {
    public section = 'directory';
    public dirty: boolean = false;

    public get siteKey() {
        if (this.$route.params.siteKey) {
            return this.$route.params.siteKey;
        }

        return null;
    }

    public get siteId() {
        if (this.$route.params.siteId) {
            return parseInt(this.$route.params.siteId, 10);
        }

        return null;
    }

    public renderSiteLink(item: any): any {
        const url = item.siteKey ? `/park/${item.siteKey}` : '';

        return {
            template: Vue.component('grid-router-link'),
            templateArgs: { text: item.name, url },
        };
    }

    /** Notifications */
    public showValidationErrors(errors): void {
        const errorsHtml = errors
            .map((item) => {
                return '<li>' + item + '</li>';
            })
            .join('');

        // First clean all previous errors
        this.clearNotifications();
        this.$notify({
            title: 'Het opslaan is niet gelukt, verbeter alstublieft de volgende waarschuwingen:',
            text: '<ul>' + errorsHtml + '</ul>',
            type: 'error',
            duration: -1,
        });
    }

    public showFailedResponse(error, consoleError): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.showError(error);
    }

    public showPending(message: string): void {
        this.$notify({
            title: message,
            type: 'loading',
            duration: -1,
        });
    }

    public showInfo(message: string): void {
        this.$notify({
            title: message,
            type: 'info',
            duration: -1,
        });
    }

    public showError(error: string): void {
        this.$notify({
            title: 'Oh snap! Er is iets misgegaan...',
            text: error,
            type: 'error',
            duration: -1,
        });
    }

    public showWarning(warning): void {
        this.$notify({
            title: `Better check that thingy, this isn't valid...`,
            text: warning,
            type: 'warning',
            duration: -1,
        });
    }

    public showSuccess(message): void {
        this.$notify({
            title: message,
            type: 'success',
        });
    }

    public clearNotifications(): void {
        this.$notify({
            clean: true,
        });
    }

    public clearAndShowSuccess(message): void {
        this.clearNotifications();
        this.showSuccess(message);
    }

    public clearAndShowError(error, consoleError = null): void {
        if (consoleError !== null) {
            console.error(consoleError);
        }

        this.clearNotifications();
        this.showError(error);
    }

    public clearAndShowWarning(warning) {
        this.clearNotifications();

        Vue.nextTick(() => {
            this.showWarning(warning);
        });
    }

    public fitElement(el: any, margin: number = 75, minHeight: number = 450): any {
        const innerHeight = window ? window.innerHeight : 0;
        const height = innerHeight - el.offsetTop - margin;

        el.style.height = height > minHeight ? `${height}px` : `${minHeight}px`;
    }

    protected validateObject(name: string = '') {
        if (name.indexOf('.') > -1) {
            const parts = name.split('.');
            if (!this.$v || !this.$v[parts[0]] || !this.$v[parts[0]][parts[1]]) {
                console.error('Cannot check if object is valid!');
            }

            this.dirty = true;
            if (this.$v[parts[0]][parts[1]].$invalid) {
                this.clearAndShowError('Niet alle verplichten zijn ingevuld.');
            }

            return !this.$v[parts[0]][parts[1]].$invalid;
        }

        if (!this.$v || !this.$v[name]) {
            console.error('Cannot check if object is valid!');
        }

        this.dirty = true;
        if (this.$v[name].$invalid) {
            this.clearAndShowError('Niet alle verplichten zijn ingevuld.');
        }

        return !this.$v[name].$invalid;
    }
}
