import Component from 'vue-class-component';
import PageRender from '../../models/PageRender';
import { bookingService, homeOwnerService } from '@/main';
import to from 'await-to-js';
import { BookingPlaceObject } from '../../models/Interfaces';
import Feature from '../../models/Feature';
import Booking from '../../models/Booking';
import Booker from '../../models/Booker';
import moment from 'moment/min/moment.min.js';
import Place from '../../models/Place';
import PlaceType from '../../models/PlaceType';
import { BModal } from 'bootstrap-vue';
import { FormWizard, TabContent, WizardButton, WizardStep } from 'vue-form-wizard/src';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import SearchPrice from '@/models/Search/Price';
import Product from '@/models/Product';
import { Watch } from 'vue-property-decorator';
import { memberModule } from '@/store/modules/member';

@Component({
    mixins: [validationMixin],
    components: {
        FormWizard,
        TabContent,
        WizardButton,
        WizardStep,
    },
    validations: {
        booking: {
            mainBooker: { required },
            places: { required },
        },
        period: {
            fromDate: { required },
            toDate: { required },
        },
    },
})
export default class CreateBookingPage extends PageRender implements IVuelidate<any> {
    public isLoading: boolean = true;
    public isCalculatingPrice: boolean = false;
    public place: Place = null;
    public travelTypes: any[] = [];
    public period: any = { fromDate: null, toDate: null };
    public filterFeatures: Feature[];
    public products: Product[];
    public booking: Booking = new Booking();
    public availableOptions = [];
    public newLicensePlate: string = '';
    public facets: any = {};
    public optionalPhysicalProducts: any = [];
    public optionalOtherProducts: any = [];
    public selectedOptionalProductIds: number[] = [];
    public $refs!: {
        confirmContinueBooking: BModal;
        createMainBookerModal: BModal;
        wizard: any;
    };

    @Watch('travelTypes', { deep: true, immediate: true })
    public async watchTravelTypes(val, old) {
        this.booking.travelGroup = [];
        let totalAmount = 0;
        this.travelTypes.forEach((type) => {
            if (type.amount > 0) {
                this.booking.travelGroup.push({
                    amountOfTravellers: type.amount,
                    travelGroupType: type.type,
                });
            }

            totalAmount += type.amount;
        });
    }

    public async mounted() {
        const placeId = this.$route.params.placeId;
        const [err, response] = await to(homeOwnerService.getAccommodation(parseInt(placeId, 10)));

        if (err) {
            this.showFailedResponse('Mislukt om accommodaties op te halen.', err);
        }

        this.place = response.data;
        this.booking.places = [this.place];

        const query = this.$route.query;

        this.period.fromDate = moment(query.fromDate).toDate();
        this.period.toDate = moment(query.toDate).toDate();

        this.booking.arrivalDate = query.fromDate as string;
        this.booking.departureDate = query.toDate as string;

        this.travelTypes = await this.getTravelTypes();

        const homeOwnerId = memberModule.member.id;

        const [mErr, mResponse] = await to(homeOwnerService.getMainbookersOfHomeOwner(this.place.siteId, homeOwnerId));
        this.booking.mainBookerId = mResponse.data[0].mainBookerId;
        this.booking.mainBooker = new Booker(mResponse.data[0]);

        this.isLoading = false;
    }

    public getBookerLabel(booker: Booker) {
        const labelSuffix = booker.emailAddress ? ` (${booker.emailAddress})` : '';
        return `${booker.firstName} ${booker.insertion ? booker.insertion : ''} ${booker.lastName}${labelSuffix}`;
    }

    public decreaseTravelType(type) {
        if (type.amount > 0) {
            type.amount--;
        } else {
            type.amount = 0;
        }
    }

    public increaseTravelType(type) {
        type.amount++;
    }

    public decreaseOptionalProduct(product) {
        if (product.amount > 0) {
            product.amount--;
        } else {
            product.amount = 0;
        }
    }

    public increaseOptionalProduct(product) {
        product.amount++;
    }

    public getPlaceType() {
        const placeType = this.place.placeType;
        return placeType || new PlaceType();
    }

    public getBookedPlaceTypes() {
        const placeTypes = [];
        this.booking.places.forEach((placeObject: BookingPlaceObject) => {
            const placeInfo = { placeType: this.place.placeType, place: this.place, preferredPlace: false };
            placeTypes.push(placeInfo);
        });

        return placeTypes;
    }

    public isEnoughCapacity(): boolean {
        const travelGroupAmount = this.booking.travelGroup.reduce((a, b) => a + b.amountOfTravellers, 0);
        const placeCapacity = this.booking.places.reduce((a, b) => a + this.getPlaceCapacity(b), 0);
        return travelGroupAmount <= placeCapacity;
    }

    public getPlaceCapacity(place) {
        return this.place.capacity;
    }

    public getMainBooker(): Booker {
        return new Booker(this.booking.mainBooker);
    }

    public addPlaceType(placeTypeId) {
        this.booking.places.push({
            placeTypeId,
            placeId: null,
            preferredPlace: false,
        });
    }

    public removePlace(index: number) {
        this.booking.places.splice(index, 1);
    }

    public onPlaceSelected(placeObject: any) {
        this.$nextTick(() => {
            placeObject.placeId = placeObject.place.placeId;
            placeObject.preferredPlace = true;
        });
    }

    public addLicensePlate() {
        this.booking.licensePlates.push(this.newLicensePlate.toUpperCase());
        this.newLicensePlate = '';
    }

    public removeLicensePlate(index) {
        this.booking.licensePlates.splice(index, 1);
    }

    public getTravelType(travelTypeId) {
        const type = this.travelTypes.find((travelType) => {
            return travelType.type === travelTypeId;
        });
        return type;
    }

    public getAllFacets() {
        const facets = [];
        Object.keys(this.facets).forEach((groupKey) => {
            this.facets[groupKey].forEach((facet) => {
                facets.push(facet);
            });
        });

        return facets;
    }

    public getTotalPriceOfBooking() {
        let total = 0;

        this.booking.places.forEach((placeObject) => {
            total += placeObject.placeType.price.calculatedPrice;
        });

        return total;
    }

    public getSelectedPlaceTypes() {
        return [this.place.placeType];
    }

    public setOptionalProducts(products) {
        this.optionalPhysicalProducts = products
            .filter((product) => product.productType === 'PhysicalProduct')
            .map((product) => {
                return {
                    amount: 0,
                    product,
                    productId: product.productId,
                };
            });

        this.optionalOtherProducts = products
            .filter((product) => product.productType !== 'PhysicalProduct')
            .map((product) => {
                return {
                    amount: 1,
                    product,
                    productId: product.productId,
                };
            });
    }

    public getOptionalPhysicalProducts() {
        return this.optionalPhysicalProducts;
    }

    public getOptionalOtherProducts() {
        return this.optionalOtherProducts;
    }

    public isProductSelected(productId: number): boolean {
        return this.selectedOptionalProductIds.indexOf(productId) > -1;
    }

    public toggleSelectedProduct(productId: number, index: number) {
        if (this.isProductSelected(productId)) {
            this.selectedOptionalProductIds.splice(index, 1);
        } else {
            this.selectedOptionalProductIds.push(productId);
        }
    }

    public async getOptionalProductsAsync() {
        const [err, response] = await to(bookingService.getOptionalProductsAsync(this.booking, this.place.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om de opties op te halen.', err);
            return;
        }
        this.setOptionalProducts(response.data);
    }

    public async calculateBookingPrice() {
        if (this.isCalculatingPrice) {
            return;
        }

        this.isCalculatingPrice = true;
        const [err, response] = await to(bookingService.calculateBookingPrice(this.booking, this.place.siteId));
        this.isCalculatingPrice = false;

        if (err || !response) {
            this.clearAndShowError('Mislukt om boeking prijs te berekenen.', err);
            this.booking.priceObject = null;
            this.booking.price = 0;
            return;
        }

        const price = response.data as SearchPrice;
        this.booking.price = price.calculatedPrice;
        this.booking.priceObject = price;
    }

    public validateStepOne() {
        return new Promise((resolve, reject) => {
            if (!this.validateObject('booking.mainBooker') && !this.validateObject('booking.places')) {
                reject();
            } else {
                this.clearNotifications();
                resolve(true);
            }
        });
    }

    public wizardOnChange(prevIndex: number, nextIndex: number) {
        if (nextIndex >= 2) {
            this.calculateBookingPrice();
        }

        if (nextIndex === 1) {
            this.getOptionalProductsAsync();
        }

        if (nextIndex === 2) {
            const otherOptions = [];

            this.selectedOptionalProductIds.forEach((id) => otherOptions.push(this.optionalOtherProducts.find((x) => x.productId === id)));

            const mergedOptions = this.optionalPhysicalProducts.concat(otherOptions);
            this.booking.selectedOptionalProducts = mergedOptions.filter((product) => product.amount > 0);
        }
    }

    public async finishBooking() {
        this.showPending('Boeking aanmaken...');
        const [err, response] = await to(bookingService.addBooking(this.booking, this.place.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om boeking aan te maken.', err);
            return false;
        }

        this.clearAndShowSuccess('Boeking aanmaken is voltooid');
        this.$router.push({ name: 'my-home-owner-accommodation', params: { placeId: this.place.placeId.toString() } });
    }

    public getTotalNights() {
        return this.booking.priceObject.totalDays;
    }

    public getDayPrice(placeTypeId) {
        const dayPrice = this.booking.priceObject.dayPrices.find((x) => x.placeTypeId === placeTypeId);
        return typeof dayPrice !== 'undefined' && dayPrice ? dayPrice.calculatedDayPrice : 0;
    }

    public getTotalDayPrice(placeTypeId) {
        return this.booking.priceObject.totalDays * this.getDayPrice(placeTypeId);
    }

    public getTotalPrice(): number {
        if (this.booking.bookingId) {
            return this.booking.price;
        }

        let total = 0;

        this.getBookedPlaceTypes().forEach((placeObject: any) => {
            total += placeObject.placeType.price.calculatedPrice;
        });

        return total;
    }

    private getTravelTypes(): any[] {
        const types = bookingService.getTravelGroupTypes();

        types.map((travelType) => {
            return (travelType.amount = 0);
        });

        return types;
    }
}
