import PropertyType from './PropertyType';
import { ImageInterface, VideoInterface, PlaceTypeInterface, FeatureInterface } from './Interfaces';

export default class PlaceType implements PlaceTypeInterface {
    public placeTypeId?: number = 0;
    public placeTypeKey: string = '';
    public propertyType: PropertyType;
    public capacity?: number = 0;
    public classification: string = '';
    public description: string = '';
    public layout: string = '';
    public name: string = '';
    public images: ImageInterface[] = [];
    public videos: VideoInterface[] = [];
    public features: FeatureInterface[] = [];
    public site: number = 0;

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }
}
