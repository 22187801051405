import Vue from 'vue';
import { BaseService } from './baseService';
import HomeOwner from '../models/HomeOwner';
import { AxiosPromise } from 'axios';
import AnnualInvoice from '@/models/AnnualInvoice';
import Booker from '@/models/Booker';
import InvoicePaymentLink from '@/models/PaymentLink';
import to from 'await-to-js';

export class HomeOwnerService extends BaseService {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'home-owners';
    private annualInvoicesEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'annual-invoices';
    private invoiceEndpoint = `${Vue.$env().InvoiceServiceEndpoint}` + 'home-owner-invoices';
    private placesEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'home-owner-places';
    private mainBookersEndpoint = `${Vue.$env().BookingServiceEndpoint}` + 'bookers';

    public getHomeOwners(includeDeleted: boolean = false): AxiosPromise<HomeOwner[]> {
        return this.get<HomeOwner[]>(`${this.endpoint}?include_deleted=${includeDeleted}`);
    }

    public getHomeOwner(id: number) {
        return this.get(`${this.endpoint}/:id`, [['id', id]]);
    }

    public getInvoices(id: number) {
        return this.get(`${this.invoiceEndpoint}?home_owner_id=:id`, [['id', id]]);
    }

    public saveHomeOwner(homeOwner: HomeOwner) {
        return this.post(`${this.endpoint}`, homeOwner);
    }

    public updateHomeOwner(homeOwner: HomeOwner) {
        return this.put(`${this.endpoint}/${homeOwner.homeOwnerId}`, homeOwner);
    }

    public deleteHomeOwner(homeOwnerId) {
        return this.delete(`${this.endpoint}/${homeOwnerId}`);
    }

    public getAccommodations(siteId?: number) {
        if (siteId) {
            return this.get(`${this.placesEndpoint}?siteId=${siteId}`);
        }

        return this.get(`${this.placesEndpoint}`);
    }

    public getAccommodation(homeOwnerPlaceId: number) {
        return this.get(`${this.placesEndpoint}/:id`, [['id', homeOwnerPlaceId]]);
    }

    public getAnnualInvoices() {
        return this.get<AnnualInvoice[]>(`${this.annualInvoicesEndpoint}`);
    }

    public getAnnualInvoice(annualInvoiceId) {
        return this.get<AnnualInvoice>(`${this.annualInvoicesEndpoint}/${annualInvoiceId}`);
    }

    public updateAnnualInvoiceModal(annualInvoice: AnnualInvoice) {
        return this.put<AnnualInvoice>(`${this.annualInvoicesEndpoint}/${annualInvoice.annualInvoiceId}`, annualInvoice);
    }

    public createAnnualInvoiceModal(annualInvoice: AnnualInvoice) {
        return this.post<AnnualInvoice>(`${this.annualInvoicesEndpoint}`, annualInvoice);
    }

    public sendAnnualInvoices(placeIds: number[]) {
        return this.post<AnnualInvoice>(`${this.annualInvoicesEndpoint}/send-invoices`, {
            placeIds,
        });
    }

    public indexateAnnualInvoices(percentage: number, placeIds: number[]) {
        const model = {
            percentage,
            placeIds,
        };

        return this.post<AnnualInvoice>(`${this.annualInvoicesEndpoint}/index-prices`, model);
    }

    public getMainbookersOfHomeOwner(siteId: number, homeOwnerId: number) {
        return this.get<Booker[]>(`${this.mainBookersEndpoint}?siteId=${siteId}&homeOwnerId=${homeOwnerId}`);
    }

    public getIdealLink(invoiceId: number) {
        return this.get<InvoicePaymentLink>(`${Vue.$env().InvoiceServiceEndpoint}invoice/${invoiceId}/payment-link`);
    }

    public async uploadMultipleHomeOwners(model: any) {
        const [err] = await to(this.post(`${this.endpoint}/upload`, { homeOwners: model }));
        if (err) {
            return false;
        }

        return true;
    }

    public async resetPassword(homeOwnerId: number): Promise<boolean> {
        const [error, response] = await to(this.post(`${this.endpoint}/${homeOwnerId}/reset-password`, null));
        if (error || !response) {
            console.error('Failed to reset password');
            return false;
        }

        return true;
    }
}
