import Vue from 'vue';
import '@progress/kendo-ui/js/kendo.dropdownlist.min.js';
import { Grid, GridToolbar, GridNoRecords } from '@progress/kendo-vue-grid';
import { DropdownsInstaller } from '@progress/kendo-dropdowns-vue-wrapper';
import { GridInstaller } from '@progress/kendo-grid-vue-wrapper';
import { InputsInstaller } from '@progress/kendo-inputs-vue-wrapper';
import { UploadInstaller } from '@progress/kendo-upload-vue-wrapper';
import { ListViewInstaller } from '@progress/kendo-listview-vue-wrapper';
import { DateinputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper';
import { PopupsInstaller } from '@progress/kendo-popups-vue-wrapper';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-vue-intl';
import DatePicker from 'vue2-datepicker';

import GridActiosComponent from '@/components/grid/grid-actions.vue';
import GridButtonComponent from '@/components/grid/grid-button.vue';
import GridTasksComponent from '@/components/grid/grid-tasks.vue';
import GridSpecialNeedsComponent from '@/components/grid/grid-special-needs.vue';
import GridPopoverComponent from '@/components/grid/grid-popover-element.vue';
import GridAmountComponent from '@/components/grid/grid-amount.vue';
import GridPercentageComponent from '@/components/grid/grid-percentage.vue';
import GridSelectComponent from '@/components/grid/grid-select.vue';
import GridNumericEditorComponent from '@/components/grid/grid-numeric-editor.vue';
import GridFilterMultiSelect from '@/components/grid/grid-filter-multi-select.vue';
import GridDateRange from '@/components/grid/grid-date-range.vue';

import MultiSelect from 'vue-multiselect';
import BootstrapVue from 'bootstrap-vue';

Vue.component('grid', Grid);
Vue.component('grid-toolbar', GridToolbar);
Vue.component('grid-norecords', GridNoRecords);

Vue.component('date-picker', DatePicker);

Vue.component('navigation-bar', require('@/components/navigation/bar.vue').default);
Vue.component('navigation-breadcrumb', require('@/components/navigation/breadcrumb.vue').default);
Vue.component('navigation-profile', require('@/components/navigation/profile.vue').default);
Vue.component('navigation-site', require('@/components/navigation/site-switch.vue').default);

Vue.component('localization-provider', LocalizationProvider);
Vue.component('intl-provider', IntlProvider);
Vue.component('multiselect', MultiSelect);

Vue.component('grid-percentage', GridPercentageComponent);
Vue.component('grid-amount', GridAmountComponent);
Vue.component('grid-popover-element', GridPopoverComponent);
Vue.component('grid-special-needs', GridSpecialNeedsComponent);
Vue.component('grid-tasks', GridTasksComponent);
Vue.component('grid-button', GridButtonComponent);
Vue.component('grid-actions', GridActiosComponent);
Vue.component('grid-select', GridSelectComponent);
Vue.component('grid-numeric-editor', GridNumericEditorComponent);
Vue.component('grid-period-range-filter', require('@/components/grid/grid-period-range-filter.vue').default);
Vue.component('grid-filter-multi', GridFilterMultiSelect);
Vue.component('grid-date-range', GridDateRange);

Vue.component('grid-router-link', require('@/components/grid/grid-router-link.vue').default);
Vue.component('site-facility', require('@/components/facilities/site-facility.vue').default);
Vue.component('spinner', require('@/components/spinner/spinner.vue').default);
Vue.component('image-thumbnail', require('@/components/images/thumbnail.vue').default);
Vue.component('image-uploader', require('@/components/images/uploader.vue').default);
Vue.component('image-list', require('@/components/images/list.vue').default);
Vue.component('video-item', require('@/components/videos/item.vue').default);
Vue.component('video-list', require('@/components/videos/list.vue').default);
Vue.component('video-add', require('@/components/videos/add.vue').default);
Vue.component('debug-component', require('@/components/debug-component/debug.vue').default);
Vue.component('days-in-week', require('@/components/date/days-in-week.vue').default);

// Price rule components
Vue.component('price-rule-deltadays', require('@/pages/settings/price-rule-components/edit/deltadays.vue').default);
Vue.component('price-rule-beginday', require('@/pages/settings/price-rule-components/edit/beginday.vue').default);
Vue.component('price-rule-endday', require('@/pages/settings/price-rule-components/edit/endday.vue').default);
Vue.component('price-rule-containsday', require('@/pages/settings/price-rule-components/edit/containsday.vue').default);
Vue.component('price-rule-singledate', require('@/pages/settings/price-rule-components/edit/singledate.vue').default);
Vue.component('price-rule-daterange', require('@/pages/settings/price-rule-components/edit/daterange.vue').default);
Vue.component('price-rule-discountcode', require('@/pages/settings/price-rule-components/edit/discountcode.vue').default);
Vue.component('price-rule-daysbeforebooking', require('@/pages/settings/price-rule-components/edit/daysbeforebooking.vue').default);
Vue.component('price-rule-accommodation', require('@/pages/settings/price-rule-components/edit/accommodation.vue').default);
Vue.component('price-rule-accommodationtype', require('@/pages/settings/price-rule-components/edit/accommodationtype.vue').default);
Vue.component('price-rule-returningvisitor', require('@/pages/settings/price-rule-components/edit/returningvisitor.vue').default);
Vue.component('price-rule-numberoftravellers', require('@/pages/settings/price-rule-components/edit/numberoftravellers.vue').default);
Vue.component('price-rule-containstravellertype', require('@/pages/settings/price-rule-components/edit/containstravellertype.vue').default);
Vue.component('price-rule-preferredplace', require('@/pages/settings/price-rule-components/edit/preferredplace.vue').default);
Vue.component('price-rule-ishomeowner', require('@/pages/settings/price-rule-components/edit/ishomeowner.vue').default);

Vue.component('price-rule-deltadays-view', require('@/pages/settings/price-rule-components/view/deltadays.vue').default);
Vue.component('price-rule-beginday-view', require('@/pages/settings/price-rule-components/view/beginday.vue').default);
Vue.component('price-rule-endday-view', require('@/pages/settings/price-rule-components/view/endday.vue').default);
Vue.component('price-rule-containsday-view', require('@/pages/settings/price-rule-components/view/containsday.vue').default);
Vue.component('price-rule-singledate-view', require('@/pages/settings/price-rule-components/view/singledate.vue').default);
Vue.component('price-rule-daterange-view', require('@/pages/settings/price-rule-components/view/daterange.vue').default);
Vue.component('price-rule-discountcode-view', require('@/pages/settings/price-rule-components/view/discountcode.vue').default);
Vue.component('price-rule-daysbeforebooking-view', require('@/pages/settings/price-rule-components/view/daysbeforebooking.vue').default);
Vue.component('price-rule-accommodation-view', require('@/pages/settings/price-rule-components/view/accommodation.vue').default);
Vue.component('price-rule-accommodationtype-view', require('@/pages/settings/price-rule-components/view/accommodationtype.vue').default);
Vue.component('price-rule-returningvisitor-view', require('@/pages/settings/price-rule-components/view/returningvisitor.vue').default);
Vue.component('price-rule-numberoftravellers-view', require('@/pages/settings/price-rule-components/view/numberoftravellers.vue').default);
Vue.component('price-rule-containstravellertype-view', require('@/pages/settings/price-rule-components/view/containstravellertype.vue').default);
Vue.component('price-rule-preferredplace-view', require('@/pages/settings/price-rule-components/view/preferredplace.vue').default);
Vue.component('price-rule-ishomeowner-view', require('@/pages/settings/price-rule-components/view/ishomeowner.vue').default);

Vue.component('booker-edit', require('@/components/booker/edit.vue').default);
Vue.component('home-owner-edit', require('@/components/home-owner/edit.vue').default);
Vue.component('string-filter', require('@/components/filters/string-filter.vue').default);
Vue.component('number-filter', require('@/components/filters/number-filter.vue').default);
Vue.component('date-filter', require('@/components/filters/date-filter.vue').default);
Vue.component('select-filter', require('@/components/filters/select-filter.vue').default);
Vue.component('place-type-search', require('@/components/place-type/search.vue').default);
Vue.component('place-type-filter-date', require('@/components/place-type/filter/date.vue').default);
Vue.component('place-type-filter-capacity', require('@/components/place-type/filter/capacity.vue').default);
Vue.component('place-type-filter-feature', require('@/components/place-type/filter/feature.vue').default);
Vue.component('place-type-filter-specialties', require('@/components/place-type/filter/specialties.vue').default);
Vue.component('booking-overview', require('@/components/booking/overview.vue').default);
Vue.component('generic-search', require('@/components/generic-search/generic-search.vue').default);
Vue.component('create-booking', require('@/components/booking/create.vue').default);
Vue.component(
    'create-or-edit-home-owner-side-action',
    require('@/components/side-actions/home-owners/create-or-edit-home-owner-side-action.vue').default,
);
Vue.component(
    'create-or-edit-annual-invoice-model-side-action',
    require('@/components/side-actions/home-owners/create-or-edit-annual-invoice-model-side-action.vue').default,
);

Vue.component('upload-home-owners-side-action', require('@/components/side-actions/home-owners/upload-home-owners-side-action.vue').default);

Vue.use(DropdownsInstaller);
Vue.use(GridInstaller);
Vue.use(InputsInstaller);
Vue.use(UploadInstaller);
Vue.use(ListViewInstaller);
Vue.use(DateinputsInstaller);
Vue.use(PopupsInstaller);
Vue.use(BootstrapVue);
