import { siteService, propertyTypeService, featureService } from '@/main';
import to from 'await-to-js';
import PlaceType from './PlaceType';
import PropertyType from './PropertyType';
import { AxiosResponse } from 'axios';
import PageRender from './PageRender';
import Feature from './Feature';
import Place from './Place';
import Vue from 'vue';
import { sitesModule } from '@/store/modules/site';

export default class BasePage extends PageRender {
    public mode: string = '';
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public placeTypes: PlaceType[] = [] as PlaceType[];
    public propertyTypes: PropertyType[] = [];
    public features: Feature[] = null;

    public async initSite() {
        await sitesModule.fetchSitesIfNeeded();
    }

    public async initBase() {
        this.mode = this.$route.params.mode;
        [this.placeTypes, this.propertyTypes, this.features] = await Promise.all([this.getPlaceTypes(), this.getPropertyTypes(), this.getFeatures()]);
        this.checkMode();
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public async getFeatures() {
        const [err, response] = await to(featureService.getFeatures());
        if (err) {
            this.showFailedResponse('Mislukt om eigenschappen op te halen', err);
            return [];
        }
        return (this.features = response.data);
    }

    public async getPlaceTypes(): Promise<PlaceType[]> {
        const [err, response] = await to(siteService.getPlaceTypes(this.siteKey));
        if (err) {
            this.showFailedResponse('Mislukt om accomodatie types op te halen', err);
            return [];
        }
        return (this.placeTypes = response.data);
    }

    public getPropertyTypes(): PropertyType[] {
        return (this.propertyTypes = propertyTypeService.getPropertyTypes());
    }

    public getPlaceType(id) {
        return this.placeTypes.find((placeType: PlaceType) => {
            return placeType.placeTypeId === id;
        });
    }

    public getPropertyType(id) {
        return this.propertyTypes.find((propertyType: PropertyType) => {
            return propertyType.propertyTypeId === id;
        });
    }

    public async getPlace(placeKey): Promise<Place> {
        let place = new Place();
        if (placeKey !== 'aanmaken') {
            const [placeErr, placeResponse] = await to<AxiosResponse<Place>>(siteService.getPlace(placeKey));
            if (placeErr) {
                this.showFailedResponse('Mislukt om park gegevens op te halen', placeErr);
                return {} as Place;
            }
            place = new Place(placeResponse.data);
        }

        place.propertyType = place.propertyType || new PropertyType();
        place.images = place.images || [];
        place.videos = place.videos || [];
        place.features = place.features || [];
        return place;
    }

    public checkMode() {
        switch (this.mode) {
            case 'edit':
                this.editMode = true;
                break;
            default:
                this.editMode = false;
                break;
        }
    }

    public edit() {
        this.dirty = false;
        this.editMode = true;
    }

    public cancelEdit() {
        this.editMode = false;
    }

    get isProduction() {
        return Vue.$env().Environment === 'Prod';
    }
}
