import BookerAddress from './BookerAddress';
import { dateHelper } from '@/main';
import { PersonInterface } from './Interfaces';
import Accommodation from '@/pages/settings/price-rule-components/edit/accommodation';
import Place from './Place';

export default class HomeOwner {
    public homeOwnerId: number;
    public firstName: string;
    public insertion: string;
    public lastName: string;
    public dateOfBirth: any;
    public emailAddress: string;
    public altEmailAddress: string;
    public phoneNumber: string;
    public altPhoneNumber: string;
    public internalNote: string;

    public streetName: string = '';
    public houseNumber: string = '';
    public postalCode: string = '';
    public city: string = '';
    public address: BookerAddress = new BookerAddress();

    public companyName: string = '';
    public iban: string = '';
    public cocNumber: string = '';
    public notes: string = '';

    public hasAccount: boolean;

    public places: Place[] = [];

    constructor(obj: any = {}) {
        Object.assign(this, obj);
    }

    public name() {
        const parts = [this.firstName, this.insertion, this.lastName];
        parts.filter((value) => !!value);

        return parts.join(' ');
    }

    public birthday() {
        return dateHelper.format(this.dateOfBirth, 'D MMMM YYYY');
    }
}
