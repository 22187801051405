import { BaseService } from './baseService';
import Vue from 'vue';

export class TenantService extends BaseService {
    private tenants: any[] = [
        { TenantId: 'bc03332d-d4e3-4e36-be7f-37ddfa064e95', Name: 'InfoParken', SiteOwnerId: 1, domain: 'recreapi.com' },
        { TenantId: '6e8701f1-94cb-43c0-9948-2f7900a6f249', Name: 'Kempen', SiteOwnerId: 2, domain: 'kempenrecreatie.recreapi.com' },
        { TenantId: '29ca3017-d737-4973-842e-9c5261c76920', Name: 'Lambalgen', SiteOwnerId: 3, domain: 'lambalgen.recreapi.com' },
        { TenantId: '29ca3017-d737-4973-842e-9c5261c76920', Name: 'Lambalgen', SiteOwnerId: 3, domain: 'eigenaren.derimboe.nl' },
    ];

    public async getTenantInfoByDomain(domain: string) {
        return new Promise((resolve, reject) => {
            const tenant = this.tenants.find((x) => x.domain === domain);
            if (tenant) {
                resolve(tenant);
            }

            resolve(this.tenants[0]);
        });
    }
}
