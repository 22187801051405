import moment from 'moment/min/moment.min.js';

export default class DateHelper {
    private dateFormat: string = 'YYYY-MM-DD';

    public loopThroughDateRange(from, to, callback) {
        const startDate = moment(from);
        const endDate = moment(to);
        const endDatePlusOne = endDate.add(1, 'days');
        let currentDate = startDate;

        while (currentDate.format(this.dateFormat) !== endDatePlusOne.format(this.dateFormat)) {
            callback(currentDate);
            currentDate = currentDate.add(1, 'days');
        }
    }

    public getDatesBetweenRange(from: any, to: any, format = '') {
        const self = this;
        const dates = [];
        format = format || this.dateFormat;

        this.loopThroughDateRange(from, to, (date: any) => {
            dates.push(self.format(date));
        });

        return dates;
    }

    public format(date: Date, format = '') {
        format = format || this.dateFormat;
        return date ? moment(date).format(format) : null;
    }

    public toDate(date: string) {
        const convertToDate = moment(date);
        if (convertToDate.isValid()) {
            return convertToDate.toDate();
        }
        return null;
    }

    public tomorrow(): Date {
        return moment(new Date()).add(1, 'days').toDate();
    }

    public today(): Date {
        return moment(new Date()).toDate();
    }

    public lastYear(): Date {
        return moment(new Date()).add(-365, 'days').toDate();
    }
}
