import Vue from 'vue';
import { BaseService } from './baseService';
import PropertyType from './../models/PropertyType';

export class PropertyTypeService extends BaseService {
    private propertyTypeEndpoint = `${Vue.$env().SiteInformationServiceEndpoint}` + 'property-types';

    public async createOrUpdatePropertyType(model: PropertyType) {
        return this.post(this.propertyTypeEndpoint, model);
    }
    public getPropertyTypes(): PropertyType[] {
        return [
            { propertyTypeId: 1, name: 'Vakantiewoning' },
            { propertyTypeId: 2, name: 'Chalet' },
            { propertyTypeId: 3, name: 'Bungalow' },
            { propertyTypeId: 4, name: 'Wellness Bungalow\r\n\r\n' },
            { propertyTypeId: 5, name: 'Ligplaats (in het geval van een haven)\r\n\r\n' },
            { propertyTypeId: 6, name: 'Kampeerplaats' },
            { propertyTypeId: 7, name: 'Trekkershut' },
            { propertyTypeId: 8, name: 'Recreatievilla' },
            { propertyTypeId: 9, name: 'MIVA (vakantiewoning voor minder valide)\r\n\r\n' },
        ];
        // return this.get<PropertyType[]>(`${this.propertyTypeEndpoint}`);
    }
    public getPropertyType(type: string) {
        return this.get<PropertyType>(`${this.propertyTypeEndpoint}/:type`, [['type', type]]);
    }
}
