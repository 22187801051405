import Vue from 'vue';
import { Prop, Component } from 'vue-property-decorator';
import PlaceType from '../../models/PlaceType';
import { SearchPlaceTypeParamsInterface } from '../../models/Interfaces';

@Component
export default class PlaceTypeSearchComponent extends Vue {
    @Prop() public placeType: PlaceType;
    @Prop() public searchParams: SearchPlaceTypeParamsInterface;
    @Prop({ default: false }) public hideFooter: boolean;

    public bookAccommodationType() {
        const query: any = { placeTypeId: this.placeType.placeTypeId };
        Object.assign(query, this.searchParams);

        this.$router.push({ name: 'create-booking', query });
    }
}
