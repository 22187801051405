import Vue from 'vue';
import { BaseService } from './baseService';
import { Invoice } from '../models/Invoice';
import { Transaction } from '../models/Transaction';
import moment from 'moment/min/moment-with-locales.min.js';

export class InvoiceService extends BaseService {
    public params: any[] = [];

    private endpoint = `${Vue.$env().InvoiceServiceEndpoint}` + 'invoice';
    private transactionEndpoint = `${Vue.$env().InvoiceServiceEndpoint}` + 'transaction';

    public getInvoices(
        siteId: number,
        invoiceId?: number,
        invoiceStatus?: number,
        invoicePaymentLinkId?: string,
        bookingId?: string,
        dueDate?: any,
        invoiceNumber?: string,
        isRegisterSale?: boolean,
        homeOwnerId?: number,
        startMonth?: string,
        endMonth?: string,
    ) {
        this.params = [['siteId', siteId]];
        let queryString = '';

        if (invoiceId !== null) {
            this.params.push(['invoiceId', invoiceId]);
            queryString += '&invoiceId=:?invoiceId';
        }

        if (invoiceStatus) {
            this.params.push(['invoiceStatus', invoiceStatus]);
            queryString += '&invoiceStatus=:?invoiceStatus';
        }

        if (invoicePaymentLinkId) {
            this.params.push(['invoicePaymentLinkId', invoicePaymentLinkId]);
            queryString += '&invoicePaymentLinkId=:?invoicePaymentLinkId';
        }

        if (bookingId) {
            this.params.push(['bookingId', bookingId]);
            queryString += '&bookingId=:?bookingId';
        }

        if (dueDate) {
            this.params.push(['dueDate', dueDate]);
            queryString += '&dueDate=:?dueDate';
        }

        if (invoiceNumber) {
            this.params.push(['invoiceNumber', invoiceNumber]);
            queryString += '&invoiceNumber=:?invoiceNumber';
        }

        if (isRegisterSale) {
            this.params.push(['isRegisterSale', isRegisterSale]);
            queryString += '&isRegisterSale=:?isRegisterSale';
        }

        if (homeOwnerId) {
            this.params.push(['homeOwnerId', homeOwnerId]);
            queryString += '&homeOwnerId=:?homeOwnerId';
        }

        if (startMonth) {
            const date = moment(startMonth, 'YYYY-MM');
            this.params.push(['startDate', date.format('YYYY-MM-DD')]);
            queryString += '&start_date=:startDate';
        }

        if (endMonth) {
            const date = moment(endMonth, 'YYYY-MM');
            date.add('month', 1);
            this.params.push(['endDate', date.format('YYYY-MM-DD')]);
            queryString += '&end_date=:endDate';
        }

        return this.get<Invoice[]>(`${this.endpoint}?siteId=:siteId${queryString}`, this.params);
    }

    public updateInvoiceState(invoiceId: number, siteId: number, invoiceState: number) {
        const url = `${this.endpoint}/:invoiceId/change-state?siteId=:siteId&nextState=:nextState`;
        this.params = [
            ['siteId', siteId],
            ['invoiceId', invoiceId.toString()],
            ['nextState', invoiceState.toString()],
        ];

        return this.put<Invoice>(url, null, this.params);
    }

    public makeInvoicePayment(invoiceId: number, siteId: number, paymentMethod: string, paidAmount: string) {
        const model = {
            amount: paidAmount,
            paymentMethod,
        };

        return this.put<Invoice>(`${this.endpoint}/:invoiceId/make-payment?siteId=:siteId`, model, [
            ['siteId', siteId],
            ['invoiceId', invoiceId],
        ]);
    }

    public getInvoiceById(invoiceId: string) {
        return this.get(`${this.endpoint}/:invoiceId`, [['invoiceId', invoiceId]]);
    }

    public getInvoiceByInvoicePaymentLinkId(invoicePaymentLinkId: string) {
        return this.get<Invoice>(`${this.endpoint}/ByPaymentLinkId?invoicePaymentLinkId=:invoicePaymentLinkId`, [
            ['invoicePaymentLinkId', invoicePaymentLinkId],
        ]);
    }

    public sendIdealLinkMail(invoiceId: number) {
        return this.post(`${this.endpoint}/:invoiceId/ideal`, null, [['invoiceId', invoiceId]]);
    }

    public createTransaction(invoiceId: number, invoicePaymentLinkId: string, fullPayment: boolean) {
        return this.post(`${this.transactionEndpoint}`, { invoiceId, invoicePaymentLinkId, fullPayment }, [
            ['invoicePaymentLinkId', invoicePaymentLinkId],
        ]);
    }

    public getTransactionByInvoiceId(invoiceId: number) {
        return this.get<Transaction>(`${this.transactionEndpoint}?invoiceId=:invoiceId`, [['invoiceId', invoiceId]]);
    }

    public sendReminders(invoiceIds: number[]): Promise<any> {
        return this.post(`${this.endpoint}/send-reminders`, { invoices: invoiceIds });
    }

    public deleteInvoice(invoiceId: number): Promise<any> {
        return this.delete(`${this.endpoint}/:invoiceId`, [['invoiceId', invoiceId]]);
    }

    public settleInvoices(firstInvoiceId: number, secondInvoiceId: number, siteId: number): Promise<any> {
        return this.post(`${this.endpoint}/settle?siteId=${siteId}`, { firstInvoiceId, secondInvoiceId });
    }

    public downloadInvoice(invoiceId: number): Promise<any> {
        return this.get(`${this.endpoint}/:invoiceId/pdf`, [['invoiceId', invoiceId]], { responseType: 'blob' });
    }

    public createCreditInvoice(invoiceId: number): Promise<any> {
        return this.post(`${this.endpoint}/:invoiceId/credit`, null, [['invoiceId', invoiceId]]);
    }

    public createInvoice(invoice: Invoice, siteId: number) {
        return this.post(`${this.endpoint}?siteId=:siteId`, invoice, [['siteId', siteId]]);
    }

    public updateInvoice(invoice: Invoice, siteId: number) {
        return this.put(`${this.endpoint}/:invoiceId/?siteId=:siteId`, invoice, [
            ['siteId', siteId],
            ['invoiceId', invoice.invoiceId],
        ]);
    }

    public getInvoiceHtml(registerInvoiceId: any): Promise<any> {
        return this.get(`${this.endpoint}/:invoiceId/html`, [['invoiceId', registerInvoiceId]]);
    }

    public mailInvoice(registerInvoiceId: any) {
        return this.post(`${this.endpoint}/:invoiceId/sendpdf`, null, [['invoiceId', registerInvoiceId]]);
    }
}
