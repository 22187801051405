import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GridActiosComponent extends Vue {
    @Prop() public item: any;
    @Prop() public actions: any[];

    public propItem: any = {};
    public propActions = [];

    public mounted() {
        if (this.$data.templateArgs) {
            this.propActions = this.$data.templateArgs.actions;
            this.propItem = this.$data.templateArgs.item;
        } else {
            this.propActions = this.actions;
            this.propItem = this.item;
        }
    }

    public actionValueTemplate(): string {
        return '<span class="icon-more-horizontal"></span>';
    }

    public actionTemplate(actionItem): string {
        return `<span class='px-2'>${actionItem.title.toString()}</span>`;
    }

    public onSelect(e): void {
        e.dataItem.function(this.propItem);
    }
}
