import Vue from 'vue';
import { BaseService } from './baseService';
import RecreapiEvent from '../models/RecreapiEvent';
import EventType from '../models/EventType';

export class BankHolidayService extends BaseService {
    private endpoint = `${Vue.$env().SiteInformationServiceEndpoint}`;

    public getBankHolidays() {
        return this.get<RecreapiEvent[]>(`${this.endpoint}/events`);
    }

    public saveBankHolidays(model: any[]) {
        return this.post<any>(`${this.endpoint}/events`, model);
    }

    public deleteBankHolidays(model: any[]) {
        return this.delete(`${this.endpoint}/events?ids=:ids`, [
            ['ids', model],
        ]);
    }

    public getTypes() {
        return this.get<EventType[]>(`${this.endpoint}/event-types`);
    }
}
