import { SearchPlaceTypeParamsInterface } from '../models/Interfaces';
import to from 'await-to-js';
import Axios from 'axios';
import { searchService, dateHelper } from '@/main';
import MapResponse from '@/models/Map/MapRespone';

export default class PlaceSearchHelper {
    private cancelSource: any = null;

    public setSearchParamsPeriod(searchParams: any, fromDate: Date, toDate: Date) {
        searchParams.fromDate = dateHelper.format(fromDate, 'YYYY-MM-DD');
        searchParams.toDate = dateHelper.format(toDate, 'YYYY-MM-DD');
    }

    public validateSearchParams(searchParams: SearchPlaceTypeParamsInterface) {
        if (!searchParams.fromDate ||
            !searchParams.toDate ||
            new Date(searchParams.toDate).getTime() <= new Date(searchParams.fromDate).getTime() ||
            searchParams.fromCapacity < 1
        ) {
            return false;
        }

        return true;
    }

    public async searchAvailablePlaceTypes(searchParams: SearchPlaceTypeParamsInterface) {
        this.cancelSearch();
        this.cancelSource = Axios.CancelToken.source();

        const config = { cancelToken: this.cancelSource.token };
        return await to(searchService.accommodation(searchParams, config));
    }

    public async map(searchParams: SearchPlaceTypeParamsInterface): Promise<MapResponse> {
        this.cancelSearch();
        this.cancelSource = Axios.CancelToken.source();

        const config = { cancelToken: this.cancelSource.token };
        const [err, response] = await to(searchService.map(searchParams, config));
        if (!err) {
            return response ? response.data as MapResponse : new MapResponse();
        }
        return null;
    }

    private cancelSearch() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }
    }
}
