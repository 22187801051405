import Place from './Place';

export default class AnnualInvoice {
    public place: Place;
    public annualInvoiceId: number = null;
    public invoiceLines: any[] = [];

    public placeId: number;
    public siteOWnerId: number;
    public siteId: number;
    public dueDate: string;

    constructor(obj?: Partial<AnnualInvoice>) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

// tslint:disable-next-line: max-classes-per-file
export class InvoiceLine {
    public description: string;
    public financeAccountId: number = null;
    public financeAccount: any = null;
    public vatPercentage: number = 21;
    public amount: number = 1;
    public price: number = 0;
}
