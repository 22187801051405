import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import moment from 'moment/min/moment.min.js';
import Booking from '../../models/Booking';
import { BModal } from 'bootstrap-vue';

@Component
export default class GridSpecialNeedsComponent extends Vue {
    @Prop() public booking: Booking;

    public $refs!: {
        infoModal: BModal;
    };

    public getDate(): string {
        return moment(this.booking.arrivalDate, 'YYYY-MM-DD').format('DD-MM-YYYY');
    }

    public openInfoModal() {
        this.$refs.infoModal.show();
    }
}
