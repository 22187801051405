export const CacheKeys = {
    ACC_SEARCH_PARAMS: 'acc_search_params',
    ACC_SEARCH_FACETS: 'acc_search_facets',
    BOOK_BOOKING: 'book_booking',
};

export default class CacheHelper {
    public setCache(key: string, object: any): void {
        const json = JSON.stringify(object);
        localStorage.setItem(key, json);
    }

    public getCache(key: string) {
        const json = localStorage.getItem(key);
        return json ? JSON.parse(json) : {};
    }
}
