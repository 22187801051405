import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment/min/moment.min.js';
import PageRender from '@/models/PageRender';
import HomeOwner from '@/models/HomeOwner';
import to from 'await-to-js';
import { homeOwnerService } from '@/main';

@Component
export default class HomeOwnerEdit extends PageRender {
    @Prop({ default: () => new HomeOwner() }) public homeOwner: HomeOwner;
    @Prop({ default: true }) public editMode: boolean;

    public homeOwnerTypes: any[] = [
        { text: 'Particulier', value: 'private' },
        { text: 'Bedrijf', value: 'company' },
    ];
}
