import moment from 'moment';
import PageRender from '@/models/PageRender';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class GridPeriodRangeFilterComponent extends PageRender {
    @Prop({ default: false }) public noEndMonth: boolean;
    public disabledDates: any = { to: null, from: null };
    public fromFilterMonth: string = null;
    public toFilterMonth: string = null;

    private monthString: string = `${new Date().getFullYear()}-` + `0${new Date().getMonth() + 1}`.slice(-2);

    public mounted() {
        this.fromFilterMonth = this.monthString;
        if (!this.noEndMonth) {
            this.toFilterMonth = this.monthString;
        }

        this.$emit('filter-change', { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth });
    }

    public notAfterOrBeforeDate(date: Date) {
        return date >= this.disabledDates.from || date < this.disabledDates.to;
    }

    public reload() {
        this.$emit('filter-change', { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth });
    }

    public input() {
        this.$emit(
            'filter-input',
            { toFilterMonth: this.toFilterMonth, fromFilterMonth: this.fromFilterMonth },
        );
    }
}
