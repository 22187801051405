import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { events } from './sideActionsEvents';

@Component
export default class SideActionsComponent extends Vue {
    public actionList: any[] = [];

    public $refs!: {
        focus;
    };

    public async mounted() {
        events.$on('pushed', (data: any) => {
            const handler = data.finishedHandler;
            const self = this;
            data.finishedHandler = (resultParam?: any) => {
                self.actionList.pop();
                if (handler) {
                    handler(resultParam);
                }
            };

            this.actionList.push(data);
        });
    }

    public cancelHandler(): void {
        this.actionList.pop();

        if (this.$refs.focus && this.$refs.focus.$el) {
            this.$refs.focus.$el.focus();
        }
    }
}
