import Component from 'vue-class-component';
import Vue from 'vue';
import Booker from '../../models/Booker';
import { Prop, Watch } from 'vue-property-decorator';
import Booking from '../../models/Booking';
import moment from 'moment/min/moment.min.js';
import PageRender from '@/models/PageRender';

@Component
export default class BookerEdit extends PageRender {
    @Prop() public booker: Booker;
    @Prop({ default: true }) public editMode: boolean;
    @Prop({ default: () => [] }) public bookings: Booking[];
    @Prop({ default: false }) public isBooker: boolean;
    @Prop({ default: false }) public isHomeOwner: boolean;
    @Prop({ default: false }) public invalid: boolean;

    public submitted: boolean = false;
    public date: Date = new Date();
    public bookerTypes: any[] = [
        { text: 'Particulier', value: 'private' },
        { text: 'Bedrijf', value: 'company' },
    ];
    public exordiumTypes: any[] = [
        { text: 'De heer', value: 'dhr' },
        { text: 'Mevrouw', value: 'mevr' },
    ];

    @Watch('date')
    public OnDateChange(val) {
        if (val) {
            this.booker.dateOfBirth = moment(val).format('YYYY-MM-DD');
        }
    }

    public mounted() {
        if (this.booker.dateOfBirth) {
            this.date = moment(this.booker.dateOfBirth, 'YYYY-MM-DD').toDate();
        } else {
            this.booker.dateOfBirth = this.date = moment().toDate();
        }
    }

    public goToBooking(booking: Booking) {
        this.$router.push({
            name: 'booking',
            params: { siteId: this.siteId.toString(), siteKey: this.siteKey, bookingKey: booking.bookingId.toString() },
        });
    }
}
