import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Component
export default class GridfilterMultiselect extends Vue {
    @Prop({ default: () => '' }) public field: string;
    @Prop({ default: () => [] }) public options: any[];
    @Prop({ default: () => null }) public filter: CompositeFilterDescriptor;
    @Prop() public defaultSelected?: any[];
    public selected: any = [];
    public isVisible: boolean = false;

    public mounted() {
        if (this.defaultSelected) {
            this.selected = this.defaultSelected;
            this.input();
        }
    }

    public input() {
        const key = `multi-${this.field}`;
        if (!this.filter) {
            return;
        }
        this.filter.filters = this.filter.filters.filter((f: any) => {
            return f.field !== this.field;
        });

        if (this.selected.length > 0) {
            const multiselectFilter = { logic: 'or', filters: [], field: this.field } as CompositeFilterDescriptor;
            this.selected.forEach((value) => {
                multiselectFilter.filters.push({
                    operator: 'contains',
                    field: this.field,
                    value,
                    key,
                } as FilterDescriptor);
            });
            this.filter.filters.push(multiselectFilter);
        }
    }

    public toggle() {
        this.isVisible = !this.isVisible;
    }
}
