import Component from 'vue-class-component';
import BasePriceRuleComponent from './base';
import { Watch } from 'vue-property-decorator';
import moment from 'moment/min/moment.min.js';

@Component({
    name: 'SingleDate',
})
export default class SingleDate extends BasePriceRuleComponent {
    public date: Date = null;

    @Watch('date')
    public onDateChange(val, oldVal) {
        if (val) {
            const date = moment(val).format('YYYY-MM-DD');
            this.condition.date = date;
        }
    }

    public created() {
        if (this.condition.date) {
            this.date = moment(this.condition.date).toDate();
        }
    }
}
