import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import PageRender from '../../models/PageRender';
import { translateAggregateResults } from '@progress/kendo-data-query';

@Component
export default class GridNumericEditorComponent extends PageRender {
    @Prop({ default: () => { return; } }) public dataItem: any;
    @Prop({ default: () => { return; } }) public saveRow: any;
    @Prop({ default: '' }) public property: string;

    private startAmount: number = 0;

    public mounted() {
        this.startAmount = this.dataItem[this.property];
    }

    public gottaSave(e) {
        if (e.keyCode === 13 && e.key === 'Enter') {
            if (!this.validValue()) {
                this.dataItem[this.property] = this.startAmount;
                return this.clearAndShowWarning('Invalid value');
            }
            this.saveRow(this.dataItem);
        } else if (e.keyCode === 27 && e.key === 'Escape') {
            this.undoChange();
        }
    }
    public undoChange() {
        this.dataItem[this.property] = this.startAmount;
        Vue.set(this.dataItem, 'inEdit', false);
    }

    public checkValue() {
        if (!this.validValue()) {
            this.dataItem[this.property] = this.startAmount;
            return this.clearAndShowWarning('Invalid value');
        }
    }

    public validValue() {
        const regex = /^[0-9.,\-]+$/;
        if (!regex.test(this.dataItem[this.property].toString())) {
            return false;
        }

        return true;
    }
}
