import { BaseService } from './baseService';
import Vue from 'vue';

export class SiteInvoiceDataService extends BaseService {
    private invoiceEndpoint = `${Vue.$env().InvoiceServiceEndpoint}` + 'site-invoice-data';

    public getSiteInvoiceData(siteId: number) {
        return this.get(`${this.invoiceEndpoint}/:siteId`, [['siteId', siteId]]);
    }

    public postSiteInvoiceData(model: any) {
        delete model.residenceFinanceAccount;
        delete model.residenceVat;
        delete model.cancelFinanceAccount;
        delete model.cancellationVat;

        delete model.gasFinanceAccount;
        delete model.energyFinanceAccount;
        delete model.waterFinanceAccount;

        return this.post(this.invoiceEndpoint, model);
    }

    public updateSiteInvoideData(model: any) {
        return this.put(this.invoiceEndpoint, model);
    }
}
