import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class GridPercentageComponent extends Vue {
    public value: string = '';

    public mounted() {
        this.value = parseFloat(this.$data.templateArgs.value).toFixed(1);
    }
}
