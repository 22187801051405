import { BaseService } from './baseService';
import Vue from 'vue';
import MeterReading from '@/models/MeterReading';
import { UpdateMeterReadingRequest } from './UpdateMeterReadingRequest';

export class MeterReadingService extends BaseService {
    private endpoint = Vue.$env().SiteInformationServiceEndpoint;

    public getMeterReadings(siteId: number) {
        return this.get<MeterReading[]>(`${this.endpoint}/meter-readings?site_id=:siteId`, [['siteId', siteId]]);
    }

    public getMeterReading(placeId: number) {
        return this.get(`${this.endpoint}/places/${placeId}/meter-readings`);
    }

    public saveMeterReadings(data: UpdateMeterReadingRequest, placeId: number) {
        return this.post(`${this.endpoint}/places/:placeId/meter-readings`, data, [['placeId', placeId]]);
    }

    public addNewMeter(placeId: number, data) {
        return this.post(`${this.endpoint}/places/:placeId/new-meter`, data, [['placeId', placeId]]);
    }

    public createAnnualGWEInvoice(data, placeId: number, siteId: number) {
        return this.post(`${this.endpoint}/places/${placeId}/meter-readings/invoice?site_id=${siteId}`, data);
    }
}
