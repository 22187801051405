export default class Enviroment {
    public name: string = '';
    public IdentityEndpoint: string = '';
    public SiteInformationServiceEndpoint: string = '';
    public BookingServiceEndpoint: string = '';
    public MediaServiceEndpoint: string = '';
    public SearchOptimizerEndpoint: string = '';
    public SearchServiceEndpoint: string = '';
    public InvoiceServiceEndpoint: string = '';
    public MailServiceEndpoint: string = '';
    public Environment: string = '';

    public enableDebug: boolean = false;

    constructor(object: any) {
        Object.assign(this, object);
    }
}
